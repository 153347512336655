import withStyles from '@material-ui/styles/withStyles';
import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { hot } from 'react-hot-loader/root';

class TravClanQuoteModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { travClanUrl, classes, travClanQuoteUsId } = this.props;
    return (
      <div className={classes.container}>
        <div
          className={classes.iframeHeader}
        >
          <Button
            className={classes.customQuoteButton}
            onClick={() => {
              window.open(`https://docs.google.com/forms/d/e/1FAIpQLSchNM0X_W7ndjGUs99b1l0KbcQ7MoeaeIvwPq7f0zTehiL4rQ/viewform?usp=pp_url&entry.1112311976=${travClanQuoteUsId}`, '_blank');
            }}
          >
            Get Custom Quote
          </Button>
        </div>
        <iframe
          title="travClanQuoteModal"
          src={`${travClanUrl}&reset_widget=true`}
          style={{ height: '600px', width: '100%', border: 'none' }}
        />
      </div>
    );
  }
}

const styles = (theme) => ({
  container: {
    flex: 1,
    flexDirection: 'column',
    width: 890,
  },
  body: {
    padding: 40,
    flex: 1,
    flexDirection: 'column',
  },
  input: {
    height: 64,
  },
  iframeHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    backgroundColor: theme.colors.primary,
    borderBottom: '0.5px solid white',
  },
  customQuoteButton: {
    color: theme.colors.white,
    backgroundColor: 'transparent',
    border: `1px solid ${theme.colors.white}`,
    fontWeight: 'bold',
    height: '32px',
    borderRadius: '16px',
    margin: '10px 10px',
  },
});

TravClanQuoteModal.propTypes = {
  classes: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  travClanUrl: PropTypes.string.isRequired,
  travClanQuoteUsId: PropTypes.string,
};

export default hot(withStyles(styles)(TravClanQuoteModal));
