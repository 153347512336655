import React from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import makeStyles from '@material-ui/styles/makeStyles';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const RadioOptions = ({
  options,
  selected,
  extraClass,
  onChange,
  defaultValue,
}) => {
  const classes = useStyles();
  return (
    <RadioGroup
      aria-label="radio"
      name="radio"
      value={selected}
      onChange={onChange}
      defaultValue={defaultValue}
      className={clsx(classes.radioGroup, extraClass)}
    >
      {options.map((option) => {
        return (
          <FormControlLabel
            value={option}
            key={option}
            control={<Radio color="primary" className={classes.radio} />}
            label={option}
            className={classes.label}
          />
        );
      })}
    </RadioGroup>
  );
};

const useStyles = makeStyles(() => ({
  radioGroup: {
    flexDirection: 'row',
    marginBottom: 16,
    paddingLeft: 16,
  },
  label: {
    paddingRight: 10,
  },
  radio: {
    width: 16,
    height: 16,
    marginRight: 4,
  },
}));

RadioOptions.propTypes = {
  extraClass: PropTypes.string,
  options: PropTypes.array.isRequired,
  selected: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
};

export default RadioOptions;
