import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import {
  Button, CardContent, LinearProgress, Typography
} from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { baseURL } from 'utils/apiEndpoints';
import axios from 'axios';
import { TIMEOUT } from 'utils/consts';
import AddShoppingCartOutlinedIcon from '@material-ui/icons/AddShoppingCartOutlined';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import clsx from 'clsx';

class RequestPackModal extends Component {
  constructor(props) {
    super(props);

    const {
      expert,
    } = props;
    this.state = {
      requestPackOptions: [],
      mode: 'view',
      activeRequestPack: expert.activeRequestPack,
      remainingSeconds: 0,
    };
  }

  componentDidMount() {
    const { showRequestPackModal } = this.props;
    if (showRequestPackModal) {
      this.getRequestPackOptions();
    }
  }

  getRequestPackOptions = async () => {
    const { showSnackbar } = this.props;
    const url = `${baseURL}requestPackOptions`;

    axios({
      method: 'get',
      url,
      headers: this.headers,
      timeout: TIMEOUT,
    }).then((response) => {
      this.setState({ requestPackOptions: response?.data?.requestPackOptions || [] });
    }).catch(() => {
      showSnackbar('Something went wrong, please reload', 'error');
    });
  }

  setInState(key, value) {
    if (value?.length > 6) {
      return;
    }
    const newState = {};
    newState[key] = value;
    this.setState(newState);
  }

  setCountdown() {
    const { remainingSeconds } = this.state;
    if (remainingSeconds > 0) {
      setTimeout(() => {
        this.setState({ remainingSeconds: remainingSeconds - 1 });
        this.setCountdown();
      }, 1000);
    }
  }

  buyClick(usId) {
    console.log('usId ', usId);
    const { requestPackOptions } = this.state;
    const requestPackOption = requestPackOptions.find((r) => r.usId === usId);
    this.setState({
      mode: 'buy',
      requestPackOption,
    });
    axios({
      method: 'post',
      url: `${baseURL}requestPacks/purchase`,
      data: {
        requestPackOptionUsId: requestPackOption.usId,
      },
      headers: this.headers,
      timeout: TIMEOUT,
    }).then((response) => {
      this.setState({
        requestPackUsId: response.data.requestPack.usId,
        remainingSeconds: 30,
      });
      this.setCountdown();
    }).catch(() => {
    });
  }

  resendOtp() {
    const { showSnackbar } = this.props;
    const { requestPackUsId } = this.state;
    axios({
      method: 'post',
      url: `${baseURL}requestPacks/resendOtp`,
      data: {
        requestPackUsId,
      },
      headers: this.headers,
      timeout: TIMEOUT,
    }).then(() => {
      this.setState({
        remainingSeconds: 30,
      });
      this.setCountdown();
      showSnackbar('OTP resent successfully', 'success');
    }).catch((error) => {
      showSnackbar(error.response?.data?.message || 'Something went wrong, please retry', 'error');
    });
  }

  confirmPayment() {
    this.setState({ confirming: true });
    const { showSnackbar } = this.props;
    const { otp, requestPackUsId } = this.state;

    const otpRegex = /^\d{6}$/;
    if (!otpRegex.test(otp)) {
      showSnackbar('Enter a valid otp', 'error');
      return;
    }
    axios({
      method: 'post',
      url: `${baseURL}requestPacks/confirm`,
      data: {
        requestPackUsId,
        otp,
      },
      headers: this.headers,
      timeout: TIMEOUT,
    }).then(() => {
      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.set('orpm', 'true');
      window.location.href = currentUrl.href;
    }).catch((error) => {
      this.setState({ confirming: false });
      showSnackbar(error.response?.data?.message || 'Something went wrong, please retry', 'error');
    });
  }

  render() {
    const {
      classes,
      toggleRequestPackModal,
      expert,
    } = this.props;
    const {
      requestPackOptions,
      mode,
      requestPackOption,
      otp,
      activeRequestPack,
      remainingSeconds,
      confirming,
    } = this.state;

    const numerator = activeRequestPack
      ? (activeRequestPack.purchasedCount + (activeRequestPack?.bonusCount || 0) - (activeRequestPack?.assignedCount || 0))
      : 0;
    const denominator = activeRequestPack
      ? (activeRequestPack.purchasedCount + (activeRequestPack?.bonusCount || 0))
      : 0;
    return (
      <div className={classes.requestPackContainer}>
        <div className={classes.header}>
          <div style={{
            fontSize: 20,
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
          }}
          >
            <GroupAddIcon className={classes.primaryColor} />
            <span style={{ paddingLeft: 10 }}>
              {mode === 'view' ? ' Leads Wallet' : ' Buy Lead Pack'}
            </span>
          </div>
          <IconButton className={classes.closeButton} onClick={toggleRequestPackModal}>
            <CloseIcon />
          </IconButton>
        </div>

        <div className={classes.body}>
          {
            mode === 'view'
              ? (
                <div>
                  <div
                    style={{
                      width: '50%',
                      borderBottom: '1px solid #DDDDDD',
                      paddingBottom: 20,
                    }}
                  >
                    <div
                      style={{
                        fontWeight: 'bold',
                        fontSize: 28,
                        paddingBottom: 10,
                      }}
                    >
                      <span className={activeRequestPack ? classes.primaryColor : classes.redColor}>{numerator}</span>
                      <span style={{ color: '#999999' }}>/{denominator}</span>
                    </div>
                    <div className={classes.progressBarContainer}>
                      <LinearProgress
                        variant="determinate"
                        value={activeRequestPack ? ((numerator / denominator) * 100) : 100}
                        classes={{
                          root: classes.progressBar,
                          bar: activeRequestPack ? classes.progress : classes.redProgress,
                        }}
                      />
                    </div>
                    {
                      activeRequestPack ? (
                        <div>
                          <div className={classes.leadsAvailableText}>Leads Available</div>
                          <div>
                            Lead flow is
                            <span className={expert.leadFlowOn ? classes.primaryColor : classes.redColor}>
                              {expert.leadFlowOn ? ' ON' : ' OFF'}
                            </span>
                          </div>
                          <div>Leads assigned at a pace of <b>{activeRequestPack.requestPackOption.pacePerDay} per day</b></div>
                        </div>
                      ) : (
                        <div>
                          <div style={{ fontSize: 24 }} className={classes.redColor}>
                            Leads Not Available
                          </div>
                          <div>Please purchase a lead pack to start lead assignment</div>
                        </div>
                      )
                    }
                  </div>

                  <div className={classes.requestPackOptionsContainer}>
                    <div
                      style={{
                        paddingBottom: 20,
                        fontWeight: 'bold',
                        fontSize: 16,
                      }}
                    >
                      {activeRequestPack ? 'Active' : 'Buy'} Lead Pack
                    </div>
                    {
                      activeRequestPack ? (
                        <div className={clsx(classes.primaryColorBorder, classes.requestPackCard)}>
                          <CardContent>
                            <div
                              className={classes.primaryColor}
                              style={{
                                fontWeight: 'bold',
                                fontSize: 18,
                              }}
                            >
                              {activeRequestPack.requestPackOption.title}
                            </div>
                            <div className={classes.activeTag}>
                              Active
                            </div>
                            <Typography variant="h6" className={classes.leadPackPrice}>
                              <span className={classes.primaryColor} style={{ fontWeight: 'bold' }}>
                                ₹{((((activeRequestPack.assignedCount || 0) / (activeRequestPack.purchasedCount + (activeRequestPack.bonusCount || 0))) * activeRequestPack.amount) / 100).toLocaleString()}
                              </span>
                              <span style={{ color: '#999999', fontWeight: 'bold' }}>
                                /₹{(activeRequestPack.amount / 100).toLocaleString()}
                              </span>
                            </Typography>
                            <div
                              className={classes.primaryColor}
                              style={{
                                fontWeight: 'bold',
                                fontSize: 18,
                              }}
                            >
                              Spent
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                paddingTop: 20,
                              }}
                            >
                              Wallet debited daily as per leads assigned
                            </div>
                          </CardContent>
                        </div>
                      ) : (
                        <div className={classes.grid}>
                          {requestPackOptions.map((pack) => (
                            <div className={classes.requestPackCard}>
                              <div
                                style={{
                                  padding: 16,
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'space-between',
                                  height: '100%',
                                }}
                              >
                                <div>
                                  <div
                                    className={classes.primaryColor}
                                    style={{
                                      fontWeight: 'bold',
                                      fontSize: 18,
                                    }}
                                  >
                                    {pack.title}
                                  </div>
                                  <ul style={{ paddingInlineStart: 20 }}>
                                    {
                                      pack.description.map((e) => {
                                        return (
                                          <li
                                            style={{ fontSize: 14 }}
                                            dangerouslySetInnerHTML={{ __html: e }}
                                          />
                                        );
                                      })
                                    }
                                  </ul>
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    paddingTop: 20,
                                  }}
                                >
                                  <Typography
                                    variant="h6"
                                    className={clsx(classes.primaryColor, classes.leadPackPrice)}
                                  >
                                    ₹{(pack.amount / 100).toLocaleString()}
                                  </Typography>

                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    className={classes.buyButton}
                                    onClick={() => this.buyClick(pack.usId)}
                                  >
                                    <AddShoppingCartOutlinedIcon />
                                    Buy
                                  </Button>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )
                    }
                  </div>
                </div>
              )
              : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '10px 0',
                  }}
                >
                  <div style={{ flex: 2, padding: '20px', borderRight: '1px solid #DDDDDD' }}>
                    <div style={{ marginBottom: '20px' }}>
                      <div style={{ fontSize: 16, fontWeight: 'bold', paddingBottom: 10 }}>
                        Lead Pack Details
                      </div>
                      <div
                        style={{
                          border: '1px solid #ddd',
                          padding: '20px',
                          borderRadius: '5px',
                        }}
                      >
                        <div className={classes.primaryColor} style={{ fontSize: 18 }}>
                          {requestPackOption.title}
                        </div>
                        <ul style={{ paddingInlineStart: 20 }}>
                          {
                            requestPackOption.description.map((e) => {
                              return (
                                <li dangerouslySetInnerHTML={{ __html: e }} />
                              );
                            })
                          }
                        </ul>
                      </div>
                    </div>

                    <div style={{ marginBottom: '20px' }}>
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: 'bold',
                          paddingBottom: 10,
                        }}
                      >
                        Payment Details
                      </div>
                      <ul>
                        <li>Please verify OTP to confirm the purchase</li>
                        <li>Actual payment will be debited from your earnings wallet</li>
                        <li>Wallet debits will happen once daily as per the leads assigned during that day</li>
                      </ul>
                    </div>

                    <div style={{ marginBottom: '20px' }}>
                      <div
                        style={{
                          fontSize: 16,
                          fontWeight: 'bold',
                          paddingBottom: 10,
                        }}
                      >
                        Amount To Be Paid
                      </div>
                      <div
                        className={classes.primaryColor}
                        style={{
                          fontSize: '24px',
                          fontWeight: 'bold',
                        }}
                      >
                        ₹{(requestPackOption.amount / 100).toLocaleString()}
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      flex: 2,
                      padding: '20px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'start',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        fontSize: 16,
                        paddingBottom: 30,
                        fontWeight: 'bold',
                      }}
                    >
                      Verify OTP to confirm payment
                    </div>
                    <div>OTP sent to</div>
                    <div><b>+91{expert.mobile}</b></div>
                    <input
                      type="text"
                      value={otp}
                      onChange={(e) => this.setInState('otp', e.target.value)}
                      placeholder="Enter OTP"
                      style={{
                        marginTop: '30px',
                        padding: '10px',
                        fontSize: '16px',
                        width: 200,
                        textAlign: 'center',
                        boxSizing: 'border-box',
                        border: '1px solid #DDDDDD',
                        borderRadius: 4,
                      }}
                    />
                    <div style={{ marginTop: '10px' }}>
                      {remainingSeconds ? (
                        <p>Resend OTP in {remainingSeconds} secs</p>
                      ) : (
                        <button
                          type="submit"
                          onClick={() => this.resendOtp()}
                          style={{
                            padding: '10px',
                            backgroundColor: 'transparent',
                            color: '#006666',
                            border: 'none',
                            cursor: 'pointer',
                            textDecoration: 'underline',
                          }}
                        >
                          Resend OTP
                        </button>
                      )}
                    </div>

                    <button
                      type="submit"
                      onClick={() => this.confirmPayment()}
                      className={classes.confirmPaymentButton}
                    >
                      {confirming ? 'Confirming...' : 'Confirm Payment'}
                    </button>
                  </div>
                </div>
              )
          }
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  requestPackContainer: {
    fontFamily: 'Lato, Arial, sans-serif',
    color: '#555555',
    minWidth: '900px',
  },
  grid: {
    display: 'flex',
    flexDirection: 'row',
  },
  body: {
    padding: '40px',
  },
  requestPackOptionsContainer: {
    paddingTop: '20px',
  },
  header: {
    padding: '40px 40px 20px 40px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '1px solid #DDDDDD',
  },
  primaryColor: {
    color: theme.colors.primary,
  },
  primaryColorBorder: {
    border: `1px solid ${theme.colors.primary} !important`,
  },
  redColor: {
    color: '#CC3254',
  },
  activeTag: {
    color: '#FFFFFF',
    backgroundColor: '#20B038',
    borderRadius: 24,
    height: 24,
    width: 72,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10px 0px',
  },
  confirmPaymentButton: {
    color: '#FFFFFF',
    backgroundColor: theme.colors.primary,
    borderRadius: 36,
    height: 36,
    width: 170,
    marginTop: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10px 0px',
  },
  progressBarContainer: {
    paddingBottom: 10,
  },
  progressBar: {
    transform: 'rotate(180deg)',
    height: '10px',
    borderRadius: '5px',
    maxWidth: '100%',
    backgroundColor: '#e0e0e0',
  },
  progress: {
    height: '10px',
    borderRadius: '5px',
    backgroundColor: theme.colors.primary,
  },
  redProgress: {
    height: '10px',
    borderRadius: '5px',
    backgroundColor: '#CC3254',
  },
  requestPackCard: {
    border: '1px solid #DDDDDD',
    borderRadius: 6,
    maxWidth: '25%',
    marginRight: 20,
  },
  buyButton: {
    textTransform: 'none',
    borderRadius: 17.5,
    fontSize: 12,
    letterSpacing: 0.5,
    color: theme.colors.primary,
    minHeight: 34,
    height: 34,
    border: `1px solid ${theme.colors.primary}`,
    '&:hover': {
      backgroundColor: theme.colors.primaryLight,
    },
  },
  leadsAvailableText: {
    color: theme.colors.primary,
    fontSize: 24,
    paddingBottom: 10,
  },
});

RequestPackModal.propTypes = {
  classes: PropTypes.object,
  displayName: PropTypes.string,
  toggleRequestPackModal: PropTypes.func,
  onClick: PropTypes.func,
  tier: PropTypes.string,
  expert: PropTypes.object,
  showSnackbar: PropTypes.object,
  showRequestPackModal: PropTypes.bool,
};

export default withStyles(styles)(RequestPackModal);
