import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import withStyles from '@material-ui/styles/withStyles';
import Button from 'UI/button';
import Autocomplete from 'UI/autocomplete';
import LabelInput from 'UI/labelInput';
// import Checkbox from 'UI/checkbox';
import PhoneNumber from 'UI/phoneNumber';
import {
  validateEmail,
  filterList,
  processRequirement,
  validateTripDetails,
} from 'utils/common';
import { ROLES } from 'utils/consts';
import Footer from 'UI/footer';
import AddTripRequirement from './addTripRequirement';

class AddRequirement extends Component {
  constructor(props) {
    super(props);
    const {
      meta,
      expert,
      request: { requirement, primaryCustomerData },
      activeRequestAction,
    } = this.props;
    let changes = {};
    this.title = 'Add new Request';
    if (requirement && (activeRequestAction === 'REASSIGN'
      || activeRequestAction === 'QUALIFY_REQUEST')) {
      const {
        email = '', firstName = '', lastName = '',
        phone = '', phoneDialCode = '', whatsApp = '', whatsAppDialCode = '',
      } = primaryCustomerData;
      changes = {
        ...processRequirement(requirement, meta.tripTypes),
        email,
        firstName,
        lastName,
        whatsApp,
        whatsAppDialCode: {
          value: whatsAppDialCode,
          valid: true,
        },
        phone,
        phoneDialCode: {
          value: phoneDialCode,
          valid: true,
        },
      };
      this.title = 'Reassign Request';
    }
    this.state = {
      step: 0,
      // isSameForWhatsApp: true,
      completed: {},
      phoneDialCode: {
        value: '+91',
        valid: true,
        item: {},
      },
      phone: '',
      whatsAppDialCode: {
        value: '+91',
        valid: true,
        item: {},
      },
      whatsApp: '',
      firstName: '',
      lastName: '',
      email: '',
      source: {
        value: '',
        valid: false,
        item: {},
      },
      origin: {
        value: '',
        valid: false,
        items: [],
      },
      places: {
        value: '',
        valid: false,
        items: [],
      },
      tripDate: '',
      tripDates: {},
      duration: '',
      flightsBooked: false,
      isFlexible: false,
      travellers: {
        adults: 0,
        children: 0,
        infants: 0,
      },
      tripTypes: {
        value: '',
        valid: false,
        items: [],
      },
      remark: '',
      errorMsg: '',
      expert: {
        value: '',
        valid: false,
        item: {},
      },
      hotnessLevel: '',
      isB2bBooking: false,
      b2bPartner: {
        value: '',
        valid: false,
        item: {},
      },
      tags: [],
      ...changes,
    };
    this.steps = ['Add lead details', 'Add trip details'];
    this.allowedReassignment = expert.roles.includes(ROLES.ADMIN)
      || expert.roles.includes(ROLES.TEAM_LEAD);
    if (this.allowedReassignment) {
      this.steps.push('Reassign trip');
    }
  }

  nextStepHandler = () => {
    // const { addRequestError } = this.props;
    const { step, expert } = this.state;
    if (step === 0) {
      const errorMsg = this.checkValidity(true);
      if (errorMsg) {
        this.setState({ errorMsg });
      } else {
        this.setState({ step: 1, errorMsg: '', completed: { 0: true } });
      }
    } else if (step === 1) {
      const errorMsg = this.checkValidity();
      if (errorMsg === '') {
        const changes = { completed: { 0: true, 1: true } };
        if (this.steps.length === 3) {
          changes.step = 2;
          changes.errorMsg = '';
        } else {
          this.createRequest();
        }
        this.setState({ ...changes });
      } else {
        this.setState({ errorMsg });
      }
    } else if (step === 2) {
      if (expert.value && !expert.valid) {
        this.setState({ errorMsg: 'Invalid expert selected' });
      } else {
        this.createRequest();
      }
      // if (!addRequestError) {
      // } else if (addRequestError) {
      //   this.createRequest();
      // } else {
      //   this.setState({ errorMsg: 'Invalid expert selected' });
      // }
    }
  };

  stepChangeHandler = (index) => {
    const { step } = this.state;
    if (index === step) {
      return;
    }
    if (step === 0) {
      this.nextStepHandler();
      return;
    }
    this.setState({ step: index });
  };

  previousHandler = () => {
    const { onDismiss } = this.props;
    const { step } = this.state;
    if (step === 1) {
      this.setState((prevState) => ({ step: prevState.step - 1 }));
    } else if (step === 2) {
      onDismiss();
    }
  };

  // togglePhoneAsWhatsapp = (isChecked) => {
  //   const { phone, phoneDialCode } = this.state;
  //   if (isChecked) {
  //     this.setState({
  //       isSameForWhatsApp: false,
  //       whatsAppDialCode: {
  //         value: '+91',
  //         valid: false,
  //         item: {},
  //       },
  //       whatsApp: '',
  //     });
  //   } else {
  //     this.setState({
  //       isSameForWhatsApp: true,
  //       whatsAppDialCode: {
  //         value: phoneDialCode.value,
  //         valid: true,
  //         item: { ...phoneDialCode.item },
  //       },
  //       whatsApp: phone,
  //     });
  //   }
  // };

  createRequest = () => {
    const {
      addRequest, activeRequestAction,
      reAssignRequest, showHotness, updateRequest,
    } = this.props;
    const {
      phoneDialCode,
      phone,
      whatsAppDialCode,
      whatsApp,
      firstName,
      lastName,
      email,
      source,
      origin,
      places,
      tripDates: { from, to },
      travellers,
      tripTypes,
      remark,
      isFlexible,
      flightsBooked,
      duration,
      expert,
      hotnessLevel,
      isB2bBooking,
      b2bPartner,
      tags,
    } = this.state;
    const params = {
      primaryCustomerData: {
        phone,
        phoneDialCode: phoneDialCode.value,
        whatsApp,
        whatsAppDialCode: whatsAppDialCode.value,
        firstName,
        lastName,
        email: email.trim(),
      },
      reachOutData: isB2bBooking ? null : {
        organic: true,
        source: source.item.code,
      },
      b2bPartner: isB2bBooking ? b2bPartner.item._id : null,
      requirement: {
        origin: origin.items.map((o) => o._id),
        places: places.items.map((d) => d._id),
        departureDate: moment(from).toISOString(),
        arrivalDate: moment(to).toISOString(),
        duration,
        travellers,
        tripTypes: tripTypes.items.map((t) => t.code),
        flightsBooked,
        flexible: isFlexible,
      },
      remarks: [{ text: remark }],
      expert: (this.steps.length === 3 && expert.valid) ? expert.item._id : undefined,
      tags,
    };
    if (showHotness) {
      params.hotnessLevel = hotnessLevel.toUpperCase();
    } else {
      delete params.hotnessLevel;
    }
    if (activeRequestAction === 'REASSIGN') {
      reAssignRequest({ details: params });
    } else if (activeRequestAction === 'QUALIFY_REQUEST') {
      updateRequest({ details: params });
    } else {
      addRequest(params);
    }
  };

  checkValidity = (firstPage) => {
    const {
      phoneDialCode,
      phone,
      whatsAppDialCode,
      whatsApp,
      // firstName,
      // lastName,
      email,
      source,
      origin,
      places,
      tripDates: { from, to },
      travellers,
      tripTypes,
      isB2bBooking,
      b2bPartner,
    } = this.state;
    const { expert } = this.props;
    let msg = '';
    if (!(phone.length >= 8 && phone.length <= 12) || !phoneDialCode.valid) {
      msg = 'Invalid phone number';
      return msg;
    }
    let minValid = false;
    if (!(whatsApp.length >= 8 && whatsApp.length <= 12) || !whatsAppDialCode.valid) {
      msg = 'Invalid whatsApp number';
      if (whatsApp.length !== 0) {
        minValid = false;
      }
    } else {
      minValid = true;
    }
    if ((_.isEmpty(email) && !expert.roles.includes(ROLES.TEAM_LEAD))
      || (!_.isEmpty(email) && !validateEmail(email.trim()))) {
      msg = 'Invalid email';
      minValid = false;
    } else {
      minValid = true;
    }
    if (!minValid) {
      return msg;
    }
    msg = '';
    if (!isB2bBooking && !source.valid) {
      msg = 'Invalid request source';
    }
    if (isB2bBooking && !b2bPartner.valid) {
      msg = 'Invalid B2B partner';
    }
    if (firstPage) {
      return msg;
    }
    const tripDetails = {
      origin,
      places,
      tripDates: { from, to },
      travellers,
      tripTypes,
    };
    const { errorMsg } = validateTripDetails(tripDetails, expert);
    msg = errorMsg;
    return msg;
  };

  handleSearchChange = (field, searchType, value) => {
    const { getSuggestions } = this.props;
    let type = 'countries';
    let extras = {};
    if (searchType === 'cities') {
      type = 'cities';
      extras = { includeAddressTypes: ['locality'] };
    } else if (searchType === 'places') {
      type = 'destinations';
      extras = { includeAddressTypes: ['country', 'locality', 'administrative_area_level_1', 'administrative_area_level_2'] };
    }
    getSuggestions(type, value, extras);
    const { [field]: stateField } = this.state;
    this.setState({
      [field]: {
        ...stateField,
        value,
      },
    });
  };

  handleItemSelection = (field, accessor, item) => {
    const { markTouched } = this.props;
    const { [field]: stateField } = this.state;
    let modField = {};
    if (stateField.items) {
      const iIndex = stateField.items.findIndex((i) => i[accessor] === item[accessor]);
      if (iIndex === -1) {
        modField = {
          value: '',
          valid: true,
          items: [item, ...stateField.items],
        };
      } else {
        modField = stateField;
      }
    } else {
      modField = {
        value: item[accessor],
        valid: true,
        item,
      };
    }
    this.setState({ [field]: modField, errorMsg: '' });
    markTouched();
  };

  handleChange = (field, value) => {
    const { markTouched, getSuggestions } = this.props;
    let changes = {};
    const { [field]: stateField } = this.state;
    if (typeof stateField === 'object' && field !== 'travellers') {
      if (field === 'expert') {
        getSuggestions('experts', value);
      }
      changes = {
        [field]: {
          ...stateField,
          value,
        },
      };
    } else {
      changes = {
        [field]: value,
      };
    }
    if (field === 'flightsBooked') {
      changes.isFlexible = false;
    }
    if (field === 'isB2bBooking') {
      changes.tags = ['DATES_NOT_FINAL'];
    }
    this.setState({ ...changes });
    markTouched();
  };

  onTripDetailsUpdate = (tripDetails) => {
    this.setState({ ...tripDetails });
  };

  // removeHandler = (field, accessor, item) => {
  //   const { [field]: varField } = this.state;
  //   const modField = { ...varField };
  //   modField.items = modField.items.filter((d) => d[accessor] !== item[accessor]);
  //   this.setState({ [field]: modField });
  // };
  //
  // removeDestination = (destination) => {
  //   const { places } = this.state;
  //   const modPlaces = places.filter((d) => d.name !== destination.name);
  //   this.setState({ places: modPlaces });
  // };
  //
  // handleDateSelection = (date, dateStr) => {
  //   const { from, to } = date;
  //   let changes = {};
  //   if (from && to) {
  //     changes = {
  //       duration: `${moment(to).diff(moment(from)) / 86400000}`,
  //     };
  //   }
  //   this.setState({ tripDate: dateStr, ...changes, tripDates: date });
  // };

  render() {
    const {
      classes,
      onDismiss,
      meta,
      suggestions,
      isAddingRequest,
      addRequestError,
      getSuggestions,
      showHotness,
      expert: loggedExpert,
    } = this.props;
    const {
      step,
      completed,
      whatsAppDialCode,
      whatsApp,
      phoneDialCode,
      phone,
      email,
      firstName,
      lastName,
      source: requestSource,
      origin,
      places,
      tripDate,
      duration,
      flightsBooked,
      isFlexible,
      travellers,
      tripTypes,
      remark,
      expert,
      errorMsg,
      // isSameForWhatsApp,
      hotnessLevel,
      // isB2bBooking,
      // b2bPartner,
      tags,
    } = this.state;
    const tripDetails = {
      origin,
      places,
      tripDate,
      duration,
      flightsBooked,
      isFlexible,
      travellers,
      tripTypes,
      remark,
      hotnessLevel,
    };
    const isOnlyMarketingManager = loggedExpert.roles.includes(ROLES.MARKETING_MANAGER) && !(loggedExpert.roles.includes(ROLES.expert));
    const leadSource = filterList(requestSource.value, meta.source || [], ['code', 'title']);
    const marketingManagerCode = ['FACEBOOK', 'INSTAGRAM', 'FB_WEBSITE_AD', 'FB_CHAT'];
    const filteredSource = isOnlyMarketingManager ? leadSource.filter((source) => marketingManagerCode.includes(source.code)) : leadSource;
    // const { b2bPartners = [] } = meta;
    let nextBtnText = 'Next';
    if (step === 1 && this.steps.length === 2) {
      nextBtnText = showHotness ? 'Qualify' : 'Create';
    } else if (step === 2) {
      nextBtnText = expert?.value
        ? 'Create & Assign Lead'
        : 'Send to Lead Bank';
    }
    if (addRequestError) {
      nextBtnText = 'Retry';
    }
    const prevBtnText = step === 2 ? 'Close' : 'Previous';
    let filteredExperts = [];
    if (step === 2) {
      filteredExperts = suggestions.experts.filter((e) => e.delete === false);
    }
    return (
      <div className={classes.container}>
        <div className={classes.headerRow}>
          <div className={classes.titleRow}>
            <Typography className={classes.requirementText}>
              {' '}
              {this.title}
            </Typography>
            <Typography className={classes.stepText}>{` (${step + 1}/${this.steps.length})`}</Typography>
            <IconButton className={classes.closeButton} onClick={onDismiss}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={classes.stepperRow}>
            <Stepper nonLinear activeStep={step} className={classes.stepper}>
              {this.steps.map((label, index) => (
                <Step active={index === step} key={label}>
                  <StepButton
                    active={index === step}
                    completed={completed[index]}
                    className={classes.stepperButton}
                    onClick={() => this.stepChangeHandler(index)}
                  >
                    {label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
          </div>
        </div>
        {step === 0 ? (
          <div className={classes.body}>
            <div className={classes.noMarginRow}>
              {/* <LabelInput label="WHATSAPP NO." extraClass={classes.right}>
                <div className={classes.phoneField}>
                  <div className={classes.phoneWrapper}>
                    <Autocomplete
                      data={suggestions.countries}
                      value={whatsAppDialCode.value}
                      popupClass={classes.popupClass}
                      accessor="displayText"
                      variant="no-outline"
                      onChange={(val) =>
                        this.handleSearchChange('whatsAppDialCode', 'country', val)}
                      onSelected={(item) =>
                         this.handleItemSelection('whatsAppDialCode', 'dialCode', item)}
                    />
                  </div>
                  <InputBase
                    type="number"
                    className={classes.bareInput}
                    autoComplete="off"
                    classes={{
                      input: classes.input,
                    }}
                    value={whatsApp}
                    onChange={(e) => this.handleChange('whatsApp', e.target.value)}
                  />
                </div>
              </LabelInput> */}
              <PhoneNumber
                showCounter
                label="PHONE NO."
                extraClass={classes.left}
                suggestions={suggestions}
                dialCode={phoneDialCode.value}
                number={phone}
                handleNumberChange={(val) => {
                  this.handleChange('phone', val);
                  this.handleChange('whatsApp', val);
                }}
                handleDialCodeChange={(val) => {
                  this.handleSearchChange('phoneDialCode', 'country', val);
                  this.handleSearchChange('whatsAppDialCode', 'country', val);
                }}
                handleDialCodeSelect={(item) => {
                  this.handleItemSelection('phoneDialCode', 'dialCode', item);
                  this.handleItemSelection('whatsAppDialCode', 'dialCode', item);
                }}
              />
              <PhoneNumber
                showCounter
                label="WHATSAPP NO."
                extraClass={classes.right}
                suggestions={suggestions}
                dialCode={whatsAppDialCode.value}
                number={whatsApp}
                handleNumberChange={(val) => this.handleChange('whatsApp', val)}
                handleDialCodeChange={(val) => this.handleSearchChange('whatsAppDialCode', 'country', val)}
                handleDialCodeSelect={(item) => this.handleItemSelection('whatsAppDialCode', 'dialCode', item)}
              />
            </div>
            {
              /*
              <div className={classes.row}>
                <div className={classes.spacing} />
                <Checkbox
                  label="Same as Phone"
                  checked={isSameForWhatsApp}
                  rootClass={classes.isSameAsPhoneBox}
                  onChange={() => this.togglePhoneAsWhatsapp(isSameForWhatsApp)}
                />
              </div>
              */
            }
            <div className={classes.row}>
              <LabelInput
                extraClass={classes.left}
                placeholder="John"
                label="FIRST NAME"
                value={firstName}
                onChange={(val) => this.handleChange('firstName', val)}
              />
              <LabelInput
                extraClass={classes.right}
                placeholder="Doe"
                label="LAST NAME"
                value={lastName}
                onChange={(val) => this.handleChange('lastName', val)}
              />
            </div>
            {
              /*
              <div className={classes.row}>
                <Checkbox
                  rootClass={clsx(classes.left, classes.checkbox)}
                  label="Is B2B Booking ?"
                  checked={isB2bBooking}
                  onChange={() => this.handleChange('isB2bBooking', !isB2bBooking)}
                />
                <Autocomplete
                  label="B2B SOURCE"
                  value={b2bPartner.value}
                  extraClass={classes.leadWrapperLabelRight}
                  inputClass={classes.leadInput}
                  data={b2bPartners}
                  accessor="name"
                  disabled={!isB2bBooking}
                  onChange={(val) => this.handleChange('b2bPartner', val)}
                  onSelected={(item) => this.handleItemSelection('b2bPartner', 'name', item)}
                />
              </div>
              */
            }
            <div className={classes.row}>
              <LabelInput
                extraClass={classes.left}
                label="EMAIL"
                placeholder="example@example.com"
                value={email}
                onChange={(val) => this.handleChange('email', val)}
              />
              <Autocomplete
                label="LEAD SOURCE"
                value={requestSource.value}
                extraClass={classes.leadWrapperLabelRight}
                inputClass={classes.leadInput}
                data={filteredSource}
                accessor="title"
                // disabled={isB2bBooking}
                onChange={(val) => this.handleChange('source', val)}
                onSelected={(item) => this.handleItemSelection('source', 'title', item)}
              />
            </div>
          </div>
        ) : null}
        {step === 1 ? (
          <>
            <AddTripRequirement
              meta={meta}
              suggestions={suggestions}
              tripDetails={tripDetails}
              onUpdate={this.onTripDetailsUpdate}
              getSuggestions={getSuggestions}
              tags={tags}
              showHotness={showHotness}
            // handleChange={this.handleChange}
            // handleDateSelection={this.handleDateSelection}
            // handleItemSelection={this.handleItemSelection}
            // handleSearchChange={this.handleSearchChange}
            // removeHandler={this.removeHandler}
            />
          </>
        ) : null}
        {step === 2 ? (
          <div className={classes.assignmentBody}>
            {/* <div className={classes.successBox}>
              <Typography className={classes.successResp}>
                {respMsg}
              </Typography>
            </div> */}
            {!addRequestError && !isOnlyMarketingManager ? (
              <Autocomplete
                label="CHOOSE AN EXPERT TO ASSIGN THIS LEAD (Optional)"
                value={expert.value}
                extraClass={classes.wrapper}
                inputClass={classes.leadInput}
                data={filteredExperts}
                accessor="fullName"
                onChange={(val) => this.handleChange('expert', val)}
                onSelected={(item) => this.handleItemSelection('expert', 'fullName', item)}
              />
            ) : null}
          </div>
        ) : null}
        <Footer errorMsg={errorMsg}>
          {step > 0 ? (
            <Button
              disabled={isAddingRequest}
              className={classes.prevButton}
              onClick={() => this.previousHandler()}
            >
              {prevBtnText}
            </Button>
          ) : null}
          <Button
            loading={isAddingRequest}
            disabled={isAddingRequest}
            className={classes.confirmButton}
            onClick={this.nextStepHandler}
          >
            {nextBtnText}
          </Button>
        </Footer>
      </div>
    );
  }
}

const styles = (theme) => ({
  container: {
    display: 'flex',
    backgroundColor: theme.colors.white,
    padding: '20px 0',
    flexDirection: 'column',
    minWidth: 480,
    borderRadius: 10,
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 40px  0 0',
    borderBottom: `1px solid ${theme.colors.underline_1}`,
  },
  requirementText: {
    fontSize: 18,
    fontWeight: 'bold',
    color: theme.colors.textDark,
  },
  stepText: {
    marginLeft: 10,
    fontSize: 18,
    fontWeight: 'bold',
    color: theme.colors.underline_1,
  },
  stepper: {
    marginLeft: 30,
    marginBottom: 20,
    padding: 10,
    flex: 1,
    maxWidth: '70%',
  },
  stepperButton: {
    padding: '5px 10px',
    width: 140,
  },
  closeButton: {
    marginLeft: 'auto',
  },
  body: {
    width: 620,
    display: 'flex',
    flexDirection: 'column',
    padding: '30px 40px 0 40px',
  },
  row: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 30,
  },
  noMarginRow: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
  },
  spacing: {
    flex: 1,
  },
  isSameAsPhoneBox: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginLeft: 11,
  },
  titleRow: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
    paddingLeft: 40,
  },
  stepperRow: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  footer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 40px',
  },
  confirmButton: {
    minWidth: 140,
    padding: '4px 12px',
    borderRadius: 25,
    fontWeight: 'bold',
    fontSize: 16,
    backgroundColor: theme.colors.primaryBackground,
    color: theme.colors.white,
    '&:hover': {
      backgroundColor: theme.colors.primary,
    },
  },
  prevButton: {
    width: 140,
    marginRight: 20,
    borderRadius: 25,
    fontSize: 16,
    backgroundColor: theme.colors.white,
    color: theme.colors.textLight,
    border: `1px solid ${theme.colors.border}`,
    '&:hover': {
      backgroundColor: theme.colors.underline,
    },
  },
  phoneField: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 4,
    height: 40,
    border: `1px solid ${theme.colors.border}`,
  },
  textField: {
    flex: 1,
  },
  checkbox: {
    flex: 1,
    marginRight: 10,
  },
  phoneWrapper: {
    alignSelf: 'center',
    width: 72,
    padding: '10px 5px 10px 15px',
    marginRight: 4,
    borderRight: `1px solid ${theme.colors.border}`,
  },
  bareInput: {
    padding: '6px 5px 6px 5px',
  },
  input: {
    padding: 0,
    height: '100%',
  },
  leadWrapper: {
    flex: 1,
    borderRadius: 4,
    height: 40,
    border: `1px solid ${theme.colors.border}`,
  },
  leadInput: {
    padding: '6px 5px 6px 15px',
    height: '100%',
    width: '100%',
  },
  leadWrapperLabelRight: {
    flex: 1,
    borderRadius: 4,
    width: '100%',
    marginLeft: 10,
  },
  multiInput: {
    height: '100%',
    width: '100%',
  },
  left: {
    flex: 1,
    marginRight: 10,
  },
  right: {
    flex: 1,
    marginLeft: 10,
  },
  popupClass: {
    width: 200,
    top: 30,
    left: -20,
  },
  errorMsg: {
    fontSize: 12,
    marginRight: 10,
    color: theme.colors.red,
    fontWeight: 'bold',
  },
  assignmentBody: {
    width: 520,
    minHeight: 400,
    display: 'flex',
    flexDirection: 'column',
  },
  successBox: {
    display: 'flex',
    flexDirection: 'row',
    padding: '12px 40px',
    marginBottom: 30,
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  successResp: {
    fontSize: 14,
    color: theme.colors.green,
  },
  wrapper: {
    flex: 1,
    width: '80%',
    padding: '20px 40px',
  },
  inputClass: {
    height: 'auto',
  },
});

AddRequirement.propTypes = {
  classes: PropTypes.object,
  onDismiss: PropTypes.func,
  addRequest: PropTypes.func,
  getSuggestions: PropTypes.func.isRequired,
  markTouched: PropTypes.func.isRequired,
  suggestions: PropTypes.object,
  meta: PropTypes.object,
  isAddingRequest: PropTypes.bool,
  expert: PropTypes.object,
  reAssignRequest: PropTypes.func,
  addRequestError: PropTypes.bool,
  request: PropTypes.object,
  activeRequestAction: PropTypes.string,
  showHotness: PropTypes.bool,
  updateRequest: PropTypes.func,
  // reassignRequest: PropTypes.string,
};

export default withStyles(styles)(AddRequirement);
