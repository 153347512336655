import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import withStyles from '@material-ui/styles/withStyles';
import InputBase from '@material-ui/core/InputBase';
import Button from 'UI/button';
import Footer from 'UI/footer';
import { isEqual, shouldShowDiffToggle } from 'utils/common';
import Switch from 'UI/switch';
import {
  ITINERARY_ACTIONS_TYPES,
  ITINERARY_READ_MODES,
  PROMO_CASH_LIMIT,
  TIMEOUT,
} from 'utils/consts';
import PricingDiff from 'components/itineraryMaker/pricingDiff';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import axios from 'axios';
import { baseURL } from 'utils/apiEndpoints';

class Pricing extends Component {
  constructor(props) {
    super(props);
    const { itineraryParts, itineraryActionType, originalPricing } = this.props;
    this.state = {
      fetchingConversion: true,
      showDiff: false,
      tokenAmount: 0,
      installments: [],
      usablePromoBalance: 0,
    };
    this.flights = [];
    this.services = [];
    this.flightsCostPrice = 0;
    this.flightsSellingPrice = 0;
    this.servicesCostPrice = 0;
    this.servicesSellingPrice = 0;
    for (let i = 0; i < itineraryParts.length; i++) {
      if (itineraryParts[i].type === 'FLIGHT') {
        this.flights.push(itineraryParts[i]);
        this.flightsCostPrice += Number(itineraryParts[i].costPrice);
        this.flightsSellingPrice += Number(itineraryParts[i].sellPrice);
      }
      if (itineraryParts[i].type === 'SERVICE') {
        this.services.push(itineraryParts[i]);
        this.servicesCostPrice += Number(itineraryParts[i].costPrice);
        this.servicesSellingPrice += Number(itineraryParts[i].sellPrice);
      }
    }
    this.flightsCostPrice /= 100;
    this.flightsSellingPrice /= 100;
    this.servicesCostPrice /= 100;
    this.servicesSellingPrice /= 100;
    this.showDiffToggle = shouldShowDiffToggle(itineraryActionType, originalPricing);
  }

  componentDidMount = () => {
    const {
      suppliers, getConversion, conversion,
      itineraryActionType, installments,
      // onUpdate,
    } = this.props;
    if (!ITINERARY_READ_MODES[itineraryActionType]) {
      this.setSupplierCost();
    } else {
      const conversionsNotPresent = [];
      for (let i = 0; i < suppliers.length; i++) {
        const supplier = suppliers[i];
        if (!supplier.conversionRate || !conversion[supplier.currency._id]) {
          conversionsNotPresent.push(supplier.currency._id);
        }
      }
      if (conversionsNotPresent.length !== 0) {
        getConversion(conversionsNotPresent.map((c) => ({ fromCurrency: c })));
      }
    }
    this.setState({
      installments,
      tokenAmount: installments?.[0]?.amount || 0,
    });
    // onUpdate('installments', installments);
    // onUpdate('tokenAmount', installments?.[0]?.amount);
    this.updateInstallments(null, true);
  };

  componentDidUpdate = (prevProps) => {
    const { conversion } = this.props;
    if (!isEqual(conversion, prevProps.conversion)) {
      this.setSupplierCost();
    }
  };

  setSupplierCost = () => {
    const {
      suppliers, getConversion, conversion,
      pricing, onUpdate,
    } = this.props;
    let totalCost = 0;
    const conversionsNotPresent = [];
    if (_.isEmpty(suppliers)) {
      totalCost = pricing.landPackage?.costPrice || 0;
    } else {
      for (let i = 0; i < suppliers.length; i++) {
        const supplier = suppliers[i];
        if (!supplier.conversionRate || !conversion[supplier.currency._id]) {
          conversionsNotPresent.push(supplier.currency._id);
        }
        totalCost += Number(supplier.costPrice) * (supplier.conversionRate / 100);
      }
    }
    if (conversionsNotPresent.length !== 0) {
      this.setState({ fetchingConversion: true });
      getConversion(conversionsNotPresent.map((c) => ({ fromCurrency: c })));
    } else {
      this.setState({ fetchingConversion: false });
      const modPrice = {
        landPackage: {},
        flights: {},
        services: {},
        ...pricing,
      };
      modPrice.landPackage.costPrice = Math.ceil(totalCost);
      modPrice.flights.costPrice = this.flightsCostPrice;
      modPrice.flights.sellPrice = this.flightsSellingPrice;
      modPrice.services.costPrice = this.servicesCostPrice;
      modPrice.services.sellPrice = this.servicesSellingPrice;
      onUpdate('pricing', modPrice);
    }
  };

  handleNestedChange = async (field, subField, value, minValueSubField) => {
    const {
      pricing,
      onUpdate,
    } = this.props;
    const pricingClone = _.cloneDeep(pricing);
    pricingClone[field] = pricingClone[field] || {};
    const finalValue = _.isEmpty(minValueSubField) ? value : Math.max(Number(pricingClone[field][minValueSubField]), Number(value));
    pricingClone[field][subField] = Math.ceil(Math.abs(finalValue));
    pricingClone.totalCostPrice = _.sum([pricingClone.landPackage.costPrice, pricingClone.flights.costPrice, pricingClone.services.costPrice]);
    pricingClone.totalSellPrice = _.sum([pricingClone.landPackage.sellPrice, pricingClone.flights.sellPrice, pricingClone.services.sellPrice]);
    pricingClone.finalSellPrice = pricingClone.totalSellPrice;
    onUpdate('pricing', pricingClone);

    if (subField === 'sellPrice') {
      await this.setState({ tokenAmount: 0 });
      this.updateInstallments(pricingClone, true);
    }
  };

  handleB2bPartnerOrderAmountChange = (event) => {
    const { onUpdate } = this.props;
    onUpdate('b2bPartnerOrderAmount', event.target.value);
  }

  handleChange = (field, value) => {
    const { pricing, onUpdate } = this.props;
    const modPrice = { ...pricing };
    modPrice[field] = value;
    onUpdate('pricing', modPrice);
  };

  focusHandler = (e) => {
    e.target.select();
  };

  toggleDiff = () => {
    this.setState((prevState) => ({ showDiff: !prevState.showDiff }));
  };

  renderRow = (title, field, cp = 0, sp = 0) => {
    const { classes, itineraryActionType, showSnackbar } = this.props;
    const margin = _.round(Number(sp) - Number(cp));
    const marginClass = margin >= 0 ? 'profit' : 'loss';
    return (
      <div className={classes.row}>
        <Typography className={classes.title}>{title}</Typography>
        <InputBase
          placeholder="&#8377; Cost price"
          className={clsx(classes.inputContainer, cp > 0 ? null : classes.loss)}
          classes={{
            input: classes.input,
          }}
          readOnly={ITINERARY_READ_MODES[itineraryActionType] || (itineraryActionType !== ITINERARY_ACTIONS_TYPES.EDIT)}
          onClick={() => {
            if (ITINERARY_READ_MODES[itineraryActionType] || (itineraryActionType !== ITINERARY_ACTIONS_TYPES.EDIT)) {
              showSnackbar('CP edit not allowed', 'warning');
            }
          }}
          onFocus={this.focusHandler}
          startAdornment={<InputAdornment position="start">₹</InputAdornment>}
          type="number"
          value={cp}
          onChange={(e) => this.handleNestedChange(field, 'costPrice', e.target.value)}
        />
        <InputBase
          placeholder="&#8377; Selling price"
          className={clsx(classes.inputContainer, sp > 0 ? null : classes.loss)}
          classes={{
            input: classes.input,
          }}
          readOnly={ITINERARY_READ_MODES[itineraryActionType]}
          onClick={() => {
            if (ITINERARY_READ_MODES[itineraryActionType]) {
              showSnackbar('SP edit not allowed in this mode', 'warning');
            }
          }}
          onFocus={this.focusHandler}
          startAdornment={<InputAdornment position="start">₹</InputAdornment>}
          type="number"
          value={sp}
          onChange={(e) => this.handleNestedChange(field, 'sellPrice', e.target.value)}
        />
        <InputBase
          placeholder="Margin"
          className={clsx(classes.inputContainer, classes.noBorder, classes[marginClass])}
          value={`₹${margin.toLocaleString()}`}
          classes={{
            input: classes.input,
          }}
          onClick={() => {
            showSnackbar('Edit SP to update margin', 'warning');
          }}
          readOnly
        />
      </div>
    );
  };

  getPriceDetails = () => {
    const { pricing: { landPackage } } = this.props;
    let cp = 0;
    let sp = 0;
    cp += Number(landPackage?.costPrice || 0) + this.flightsCostPrice + this.servicesCostPrice;
    sp += Number(landPackage?.sellPrice || 0) + this.flightsSellingPrice + this.servicesSellingPrice;
    const margin = (sp - cp);
    return {
      cp,
      sp,
      margin,
    };
  };

  async updateTokenAmount(tokenAmount) {
    await this.setState({
      tokenAmount,
    });
    _.debounce(() => this.updateInstallments(), 300)();
  }

  updateInstallments(pricingClone, tokenChangeAllowed) {
    const {
      headers,
      itineraryParts,
      pricing,
      onUpdate,
      showSnackbar,
      request,
    } = this.props;
    const { tokenAmount } = this.state;
    const url = `${baseURL}itinerary/installments`;
    const multiplyBy100 = (obj) => {
      for (const key in obj) {
        if (typeof obj[key] === 'number') {
          // eslint-disable-next-line no-param-reassign
          obj[key] *= 100;
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
          multiplyBy100(obj[key]);
        }
      }
      return obj;
    };
    const pricingToSend = multiplyBy100(_.cloneDeep(pricingClone || pricing));
    axios({
      method: 'post',
      url,
      data: {
        itinerary: {
          request: request?._id,
          pricing: pricingToSend,
        },
        earliestTravelDate: _.min(itineraryParts.map((ip) => ip.startTimeStamp)),
        tokenAmount,
      },
      headers,
      timeout: TIMEOUT,
    })
      .then((resp) => {
        const newTokenAmount = tokenChangeAllowed
          ? Math.max(resp.data.installments[0].amount, tokenAmount)
          : tokenAmount;
        this.setState({
          installments: resp.data.installments,
          originalInstallments: resp.data.originalInstallments,
          tokenAmount: newTokenAmount,
          usablePromoBalance: resp.data.usablePromoBalance,
        });
        onUpdate('installments', resp.data.installments);
        onUpdate('tokenAmount', newTokenAmount);
        if (newTokenAmount < resp.data.originalInstallments[0].amount) {
          showSnackbar(`Token amount should not be less than ₹${_.round(resp.data.originalInstallments[0].amount/100)}`, 'error', [], 2000);
        }
      })
      .catch(() => {
      });
  }

  render() {
    const {
      classes, pricing, itineraryActionType, version,
      errorMsg, nextHandler, originalPricing,
      isB2bBooking, b2bPartnerOrderAmount,
    } = this.props;
    const {
      fetchingConversion,
      showDiff,
      tokenAmount,
      installments,
      originalInstallments,
      usablePromoBalance,
    } = this.state;
    const {
      landPackage,
      flights,
      services,
    } = pricing;
    const { cp, sp, margin } = this.getPriceDetails();
    return (
      <div className={classes.container}>
        <div className={classes.body}>
          {this.showDiffToggle ? (
            <div className={classes.changeHeader}>
              <Switch
                checked={showDiff}
                onToggle={this.toggleDiff}
                label="VIEW CHANGES"
              />
            </div>
          ) : null}
          {version === 2 ? (
            <>
              {/*
              <Typography className={classes.note}>
                NOTES:
              </Typography>
              <Typography className={classes.notes}>
                - By clicking on request booking button communication will be send to customer,
                to pay the booking amount.
              </Typography>
              <Typography className={classes.notes}>
                - Only after receiving booking amount from customer, only then
                communication will be sent to operation team to process the booking.
              </Typography>
              <Typography className={classes.notes}>
                - Booking status will be updated on CRM by Operation team and same will be
                reflected on customers booking page.
              </Typography>
              */}
              {itineraryActionType === ITINERARY_ACTIONS_TYPES.MODIFY_ITINERARY ? (
                <>
                  <Typography className={classes.notes}>
                    - After modifying, itinerary will be send to Team lead for approval, only after
                    approval changes will be visible to customer.
                  </Typography>
                  <Typography className={classes.notes}>
                    - In caase of rejection, please contact your Team Lead for
                    further clarification.
                  </Typography>
                </>
              ) : null}
            </>
          ) : null}
          <div className={classes.header}>
            <div className={classes.headSpacing} />
            <Typography className={classes.headTitle}>Cost Price</Typography>
            <Typography className={classes.headTitle}>Selling Price</Typography>
            <Typography className={classes.headTitle}>Margin</Typography>
          </div>
          {showDiff ? (
            <PricingDiff
              oldData={originalPricing}
              data={pricing}
            />
          )
            : (
              <>
                {this.renderRow('Land Package', 'landPackage', landPackage?.costPrice, landPackage?.sellPrice)}
                {this.flights.length
                  ? this.renderRow('Flights', 'flights', flights?.costPrice, flights.sellPrice) : null}
                {this.services.length
                  ? this.renderRow('Services', 'services', services.costPrice, services.sellPrice) : null}
                {
                  /*
                  <div className={classes.row}>
                    <Typography className={classes.title}>Offer applied</Typography>
                    <InputBase
                      className={classes.inputContainer}
                      value={offer}
                      readOnly={ITINERARY_READ_MODES[itineraryActionType]}
                      onChange={(e) => this.handleNestedChange('offer', '', e.target.value)}
                    />
                  </div>
                   */
                }
                {
                  isB2bBooking && itineraryActionType === 'request_booking' ? (
                    <div className={classes.row}>
                      <Typography className={classes.title}>Total amount paid by customer to cred</Typography>
                      <InputBase
                        className={classes.inputContainer}
                        value={b2bPartnerOrderAmount}
                        onChange={this.handleB2bPartnerOrderAmountChange}
                      />
                    </div>
                  ) : null
                }
                <div className={classes.totalRow}>
                  <Typography className={clsx(classes.title, classes.totalText)}>Total</Typography>
                  <InputBase
                    className={clsx(classes.inputContainer, classes.totalInput)}
                    classes={{
                      input: classes.input,
                    }}
                    type="string"
                    readOnly
                    value={`₹${cp.toLocaleString()}`}
                  />
                  <InputBase
                    style={{ fontWeight: 'bold' }}
                    className={clsx(classes.inputContainer, classes.totalInput)}
                    classes={{
                      input: classes.input,
                    }}
                    type="string"
                    readOnly
                    value={`₹${sp.toLocaleString()}`}
                  />
                  <InputBase
                    className={
                      clsx(classes.inputContainer,
                        classes.totalInput,
                        ((margin < 0) || ((usablePromoBalance > 0) && (margin <= PROMO_CASH_LIMIT)))
                          ? classes.redColor
                          : classes.primaryColor)
                    }
                    style={{
                      fontWeight: 'bold',
                    }}
                    classes={{
                      input: classes.input,
                    }}
                    type="string"
                    readOnly
                    value={`₹${margin.toLocaleString()}`}
                  />
                </div>
                {
                  usablePromoBalance > 0
                    ? (
                      <div
                        className={classes.row}
                        style={{
                          display: 'flex',
                          alignItems: 'end',
                          color: 'red',
                          fontSize: 14,
                          fontFamily: 'Lato',
                          marginRight: 15,
                          flexDirection: 'column',
                        }}
                      >
                        <div>Client can use ₹{PROMO_CASH_LIMIT.toLocaleString()} from promo cash, this will be deducted from total margins</div>
                        <div>
                          {
                            margin <= PROMO_CASH_LIMIT
                              ? 'You will make no earning on this booking. Please add more margin'
                              : ''
                          }
                        </div>
                      </div>
                    )
                    : (
                      <div style={{ marginBottom: 20 }} />
                    )
                }
                <div className={classes.row}>
                  <div style={{
                    flex: 4,
                  }}
                  />
                  <div style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                  >
                    <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                    >
                      <Typography className={clsx(classes.title, classes.tokenText)}>Token</Typography>
                      <InputBase
                        className={
                          clsx(classes.inputContainer,
                            classes[((tokenAmount === 0) || (tokenAmount >= originalInstallments?.[0]?.amount))
                              ? 'profit'
                              : 'loss'])
                        }
                        style={{ display: 'flex', justifyContent: 'flex-end', flex: 1 }}
                        classes={{
                          input: classes.input,
                        }}
                        startAdornment={<InputAdornment position="start">₹</InputAdornment>}
                        type="number"
                        onChange={(e) => this.updateTokenAmount(e.target.value * 100)}
                        value={(_.isNil(tokenAmount) ? installments?.[0]?.amount : tokenAmount)/100}
                      />
                    </div>
                    <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      fontSize: 12,
                      fontFamily: 'Lato',
                      marginTop: 20,
                    }}
                    >
                      <InfoOutlinedIcon style={{ fontSize: 16 }} /> <span style={{ marginLeft: 10 }}>Tips</span>
                    </div>
                    <ul className={classes.tipsList}>
                      <li>Aim for 10% margins</li>
                      <li>Keep ₹{PROMO_CASH_LIMIT.toLocaleString()} extra margin for offering as discounts for serious clients</li>
                    </ul>
                  </div>
                </div>
              </>
            )}
        </div>
        {nextHandler ? (
          <Footer
            errorMsg={errorMsg}
          >
            <Button
              onClick={nextHandler}
              disabled={fetchingConversion}
              className={clsx(classes.createButton, fetchingConversion && classes.disabled)}
            >
              Save & Continue
            </Button>
          </Footer>
        ) : null}
      </div>
    );
  }
}

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    backgroundColor: theme.colors.white,
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '20px 40px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  changeHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: 10,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  headSpacing: {
    width: 140,
    backgroundColor: 'transparent',
  },
  tokenText: {
    flex: 1,
  },
  headTitle: {
    flex: 1,
    marginLeft: 16,
    padding: '10px 15px',
    height: 40,
    color: theme.colors.textDark,
    textAlign: 'right',
  },
  title: {
    fontSize: 16,
    color: theme.colors.black,
    width: 140,
    height: 40,
    display: 'flex',
    alignItems: 'center',
  },
  inputContainer: {
    color: theme.colors.black,
    flex: 1,
    marginLeft: 16,
    padding: '10px 15px',
    height: 40,
    boxSizing: 'border-box',
    borderRadius: 4,
    border: `1px solid ${theme.colors.border}`,
  },
  input: {
    textAlign: 'right',
  },
  focusedInput: {
    border: `1px solid ${theme.colors.primary}`,
  },
  profit: {
    color: theme.colors.green,
  },
  loss: {
    color: theme.colors.red,
  },
  totalRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 10,
    borderTop: `1px solid ${theme.colors.border}`,
  },
  totalText: {
    color: theme.colors.textDark,
  },
  alignRight: {
    justifyContent: 'end',
  },
  totalInput: {
    color: theme.colors.textDark,
    border: 'none',
  },
  redColor: {
    color: theme.colors.red,
  },
  primaryColor: {
    color: theme.colors.primary,
  },
  summaryRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
    marginLeft: 'auto',
  },
  summaryLabel: {
    fontSize: 16,
    color: theme.colors.textDark,
    letterSpacing: 0.5,
  },
  createButton: {
    width: 180,
    fontSize: 14,
    borderRadius: 25,
    fontWeight: 'bold',
    color: theme.colors.white,
    backgroundColor: theme.colors.primaryBackground,
    '&:hover': {
      backgroundColor: theme.colors.primary,
    },
  },
  disabled: {
    backgroundColor: theme.colors.grey,
    color: theme.colors.white,
    cursor: 'not-allowed',
  },
  note: {
    margin: '0 0 8px',
    fontWeight: 'bold',
    fontSize: 14,
    color: theme.colors.textDark,
  },
  notes: {
    margin: '6px 0',
    fontWeight: 'bold',
    fontSize: 14,
    color: theme.colors.textLight,
  },
  tipsList: {
    marginTop: 10,
    paddingLeft: 17,
    fontSize: 12,
    fontFamily: 'Lato',
  },
  noBorder: {
    border: 'none',
  },
});

Pricing.propTypes = {
  classes: PropTypes.object,
  pricing: PropTypes.object.isRequired,
  isB2bBooking: PropTypes.bool,
  b2bPartnerOrderAmount: PropTypes.number,
  itineraryParts: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired,
  nextHandler: PropTypes.func,
  errorMsg: PropTypes.string,
  suppliers: PropTypes.array.isRequired,
  getConversion: PropTypes.func.isRequired,
  conversion: PropTypes.object.isRequired,
  itineraryActionType: PropTypes.string.isRequired,
  originalPricing: PropTypes.object,
  routes: PropTypes.object,
  headers: PropTypes.object,
  expert: PropTypes.object.isRequired,
  version: PropTypes.number,
  installments: PropTypes.array,
  showSnackbar: PropTypes.func,
  request: PropTypes.object.isRequired,
};

export default withStyles(styles)(Pricing);
