import ButtonBase from '@material-ui/core/ButtonBase';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AlarmOnIcon from '@material-ui/icons/AlarmOnOutlined';
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined';
import AttachmentIcon from '@material-ui/icons/Attachment';
import CancelIcon from '@material-ui/icons/CancelOutlined';
import CardTravelIcon from '@material-ui/icons/CardTravel';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ContactIcon from '@material-ui/icons/ContactSupportOutlined';
import CloneIcon from '@material-ui/icons/FileCopyOutlined';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import MoreVert from '@material-ui/icons/MoreVert';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import UnarchiveOutlinedIcon from '@material-ui/icons/UnarchiveOutlined';
import withStyles from '@material-ui/styles/withStyles';
import clsx from 'clsx';
import * as _ from 'lodash';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
// import UnreachableIcon from '@material-ui/icons/PhoneDisabled';
import Check from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import PageViewIcon from '@material-ui/icons/PageviewOutlined';
import Send from '@material-ui/icons/Send';
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';
import CancelBookingIcon from 'assets/icons/cancelBooking';
import ModifyBookingIcon from 'assets/icons/modifyBooking';
// import edit from 'assets/svg/edit.svg';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import whatsAppImg from 'assets/svg/whatsapp.svg';
import axios from 'axios';
import Button from 'UI/button';
import DotLine from 'UI/dotLine';
import Remarks from 'UI/remarks';
import { baseURL } from 'utils/apiEndpoints';
import {
  getItineraryParts,
  getReachOutInfo, headerGenerator,
  isEqual,
  uuid,
} from 'utils/common';
import {
  PROMO_CASH_LIMIT,
  ROLES,
  STATUS_IMGS,
  TIMEOUT,
} from 'utils/consts';
import RequestSummary from './requestSummary';

const walletsTypes = {
  CREDIT_CASH: 'Flynote Wallet',
  REFUND_CASH: 'Bank Account',
  TRUE_CASH: 'Bank Account',
};
class RequestDetails extends Component {
  constructor(props) {
    super(props);
    const { token, expert, paymentMode = [] } = this.props;
    this.headers = headerGenerator(token);
    this.tabs = ['CURRENT', 'FORM-FILLS'];
    this.options = [{
      Icon: ContactIcon,
      title: 'Request quotation',
    }, {
      Icon: Send,
      title: 'Resend itinerary',
    }, {
      Icon: EditIcon,
      title: 'Edit itinerary',
    }, {
    //   Icon: VisibilityIcon,
    //   title: 'Preview itinerary',
    // }, {
      Icon: DeleteIcon,
      title: 'Delete itinerary',
    }];
    this.state = {
      selectedTab: 'CURRENT',
      isOps: expert.roles.includes(ROLES.OPERATION),
      showQuotation: expert.roles.includes(ROLES.EXPERT),
      exceptFellow: expert.roles.some((role) => role !== ROLES.FELLOW),
    };
    this.ignoreActions = {
      MARK_UNREACHABLE: true,
      FOLLOW_UP: true,
      REQUEST_QUOTATION: false,
      SEND_ITINERARY: true,
      RESEND_ITINERARY: true,
      ACCEPT_ITINERARY: true,
      UPDATE_ITINERARY: true,
      REQUEST_BOOKING: true,
      MODIFY_BOOKING_TECH: true,
      MODIFY_BOOKING: true,
      CANCEL_REQUEST: true,
      APPROVE_BOOKING_MODIFICATION: true,
      REJECT_BOOKING_MODIFICATION: true,
      ACCEPT_PAYMENT: true,
      TOGGLE_ARCHIVE_ITINERARY: true,
      RE_SYNC: !expert.roles?.includes(ROLES.TEAM_LEAD),
    };
    this.myRoles = {};
    for (let i = 0; i < expert.roles.length; i++) {
      this.myRoles[expert.roles[i]] = true;
    }
    const { roles } = expert;
    this.canConfirmAcceptBooking = roles.includes(ROLES.OPERATION) || roles.includes(ROLES.ADMIN);
    this.isExpert = roles.includes(ROLES.EXPERT);
    this.paymentModeMap = {};
    for (let i = 0; i < paymentMode.length; i++) {
      this.paymentModeMap[paymentMode[i].code] = paymentMode[i].title;
    }
  }

  componentDidUpdate = (prevProps) => {
    const { selectedTab } = this.state;
    const {
      request, classes,
      getRefundData, refundData,
      getVirtualAccountData,
      bookings, getBookings,
    } = this.props;
    if (!isEqual(prevProps, this.props)) {
      if (selectedTab === 'PAYMENT'
        && (request.orderInfo === undefined || request.orderInfo.orderData === undefined
          || request.orderInfo.orderData.length === 0)) {
        this.setState({ selectedTab: 'CURRENT' });
      }
      if (selectedTab === 'BOOKINGS' && request.bookings.length === 0) {
        this.setState({ selectedTab: 'CURRENT' });
      }
      if (selectedTab === 'BOOKINGS' && !bookings[request._id]) {
        getBookings();
      }
      if (selectedTab === 'PAYMENT' && !refundData[request._id]) {
        // if (expert.roles.includes(ROLES.TEAM_LEAD) || expert.roles.includes(ROLES.PAYMENT) || expert.roles.includes(ROLES.ACCOUNTS)) {
        getVirtualAccountData();
        // }
        getRefundData();
      }
    }
    if (!isEqual(request._id, prevProps.request._id) && prevProps.request._id && request._id) {
      const container = document.getElementById('container');
      if (container) {
        container.classList.add(classes.enterLeft);
        setTimeout(() => {
          if (container) {
            container.classList.remove(classes.enterLeft);
          }
        }, 400);
      }
    }
  };

  handleTabSelection = (tab) => {
    const {
      getBookings, request, bookings,
      getRefundData, refundData,
      getVirtualAccountData,
    } = this.props;
    const { selectedTab } = this.state;
    if (selectedTab !== tab) {
      if (tab === 'BOOKINGS' && !bookings[request._id]) {
        getBookings();
      }
      if (tab === 'PAYMENT' && !refundData[request._id]) {
        // if (expert.roles.includes(ROLES.TEAM_LEAD) || expert.roles.includes(ROLES.PAYMENT) || expert.roles.includes(ROLES.ACCOUNTS)) {
        getVirtualAccountData();
        // }
        getRefundData();
      }
      this.setState({ selectedTab: tab });
    }
  };

  box = (classes, title, value, className) => {
    return (
      <div className={className}>
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.value}>{value}</Typography>
      </div>
    );
  };

  handleChange = (field, value) => {
    this.setState({ [field]: value });
  };

  copyBookingLink = async () => {
    const { showSnackbar } = this.props;
    const url = process.env.REACT_APP_ENV === 'production'
      ? 'flynote.com' : 'staging.flynote.com';
    const bookingURL = `https://${url}/user/trips`;
    await navigator.clipboard.writeText(bookingURL);
    showSnackbar('Customer booking link copied!!', 'success');
  };

  getPublicLink = (requestUuid, itineraryId) => {
    const url = `https://${process.env.REACT_APP_ENV === 'production'
      ? 'flynote.com' : 'staging.flynote.com'}/user/trips/${requestUuid}/itineraries/${itineraryId}`;
    return url;
  };

  copyPublicLink = async (requestUuid, itineraryId) => {
    const { showSnackbar } = this.props;
    const url = this.getPublicLink(requestUuid, itineraryId);
    await navigator.clipboard.writeText(url);
    showSnackbar('Public link copied', 'success');
  };

  downloadPdf = async (request, itinerary) => {
    const { showSnackbar } = this.props;
    if (!['ITINERARY_SENT', 'CONFIRMED', 'APPROVED'].includes(itinerary.status)) {
      showSnackbar('PDF download allowed for sent, confirmed and approved states only', 'error');
      return;
    }
    const url = `${baseURL}request/${request._id.toString()}/itineraries/${itinerary._id.toString()}/pdfUrl`;
    axios({
      method: 'get',
      url,
      headers: this.headers,
      timeout: TIMEOUT,
    }).then((response) => {
      if (_.isNil(response.data?.pdfUrl)) {
        showSnackbar('PDF download failed', 'error');
      } else {
        const link = document.createElement('a');
        link.href = response.data.pdfUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        showSnackbar('PDF download completed', 'success');
      }
    }).catch(() => {
      showSnackbar('PDF download failed, please retry.', 'error');
    });
    showSnackbar('PDF will be downloaded shortly', 'success');
  };

  handleItineraryAction = (index, option, anchorEl) => {
    const { request, onItineraryAction, actionHandler } = this.props;
    const { itineraries } = request;
    const itinerary = itineraries[index];
    if (option === 'Request quotation') {
      onItineraryAction('quote', itinerary);
    } else if (option === 'Send itinerary' || option === 'Resend itinerary') {
      onItineraryAction('send', itinerary);
    } else if (option === 'Edit itinerary') {
      onItineraryAction('edit', itinerary);
    // } else if (option === 'Preview itinerary') {
    //   if (itinerary.status === 'DRAFTED' || (!itinerary.link && !itinerary.document?.link)) {
    //     onItineraryAction('preview_itinerary', itinerary);
    //   } else {
    //     window.open(itinerary.link || itinerary.document.link, '_blank');
    //   }
    } else if (option === 'Clone & Edit') {
      onItineraryAction('clone_edit_itinerary', itinerary);
    } else if (option === 'Save as Template') {
      actionHandler('itinerary_template', itinerary);
    } else if (option === 'Remove as Template') {
      onItineraryAction('remove_as_template', itinerary);
    } else if (option === 'Archive') {
      onItineraryAction('archive', itinerary);
    } else if (option === 'Unarchive') {
      onItineraryAction('unarchive', itinerary);
    } else if (option === 'Preview') {
      window.open(this.getPublicLink(request.uuId, itinerary._id), '_blank');
    } else if (option === 'Delete itinerary') {
      onItineraryAction('delete', itinerary);
    } else if (option === 'request_booking') {
      onItineraryAction(option, itinerary);
    } else if (option === 'mark_accepted') {
      onItineraryAction(option, itinerary);
    } else if (option === 'View final itinerary') {
      onItineraryAction('view_itinerary', itinerary);
    } else if (option === 'Modify itinerary') {
      onItineraryAction('modify_itinerary', itinerary);
    } else if (option === 'Modify itn. (T)') {
      onItineraryAction('modify_itinerary_tech', itinerary);
    } else if (option === 'approve_reject_itinerary') {
      onItineraryAction('approve_reject_itinerary', itinerary);
    } else if (option === 'Cancel itinerary') {
      onItineraryAction('cancel_itinerary', itinerary);
    }
    if (anchorEl) {
      this.handleChange(anchorEl, null);
    }
  };

  // handleBookingAction = (index, option, anchorEl) => {
  //   const { onBookingAction } = this.props;
  //   if (option === 'Modify booking') {
  //     onBookingAction('MODIFY_BOOKING', index);
  //   } else if (option === 'Cancel booking') {
  //     onBookingAction('CANCEL_BOOKING', index);
  //   }
  //   if (anchorEl) {
  //     this.handleChange(anchorEl, null);
  //   }
  // };

  getItineraryBookedInfo = (bookingIndex, bookingId) => {
    const { request, bookings } = this.props;
    const booking = request.bookings.find((bookOb) => bookOb._id === bookingId) || bookings[bookingId] || request.bookings[bookingIndex];
    const { supplier } = booking;
    const { itinerary, itineraryParts: bookedParts } = getItineraryParts(request, bookingId);
    let flights = 0;
    let stays = 0;
    let activities = 0;
    let transfers = 0;
    let services = 0;
    const flightParts = [];
    for (let i = 0; i < bookedParts.length; i++) {
      if (!bookedParts[i]) {
        continue;
      }
      if (bookedParts[i].type === 'STAY') {
        stays++;
      } else if (bookedParts[i].type === 'FLIGHT') {
        flights++;
        flightParts.push(bookedParts[i]);
      } else if (bookedParts[i].type === 'TRANSFER') {
        transfers++;
      } else if (bookedParts[i].type === 'ACTIVITY') {
        activities++;
      } else if (bookedParts[i].type === 'SERVICE') {
        services++;
      }
    }
    const matchingPart = bookedParts.find((p) => (p ? p.supplier._id === supplier : false));
    const currencyCode = matchingPart?.supplier.currency.code;
    return {
      itinerary,
      currencyCode,
      flights,
      stays,
      activities,
      transfers,
      services,
      flightParts,
    };
  };

  getBookedTypeStr = (activities, transfers, stays, flights, services) => {
    const bookings = [];
    if (activities) {
      bookings.push(`${activities} activities`);
    }
    if (transfers) {
      bookings.push(`${transfers} transfers`);
    }
    if (stays) {
      bookings.push(`${stays} stays`);
    }
    if (flights) {
      bookings.push(`${flights} flights`);
    }
    if (services) {
      bookings.push(`${services} services`);
    }
    return bookings.join(' | ');
  };

  displayPaymentDetails = (paymentDetails) => {
    const displayDetails = [];
    for (let i = 0; i < paymentDetails.length; i++) {
      const { status } = paymentDetails[i];
      if (status === 'SUCCESS' || status === 'CAPTURED'
        || status === 'REFUND_SUCCESSFUL' || status === 'REFUND_INITIATED' || status === 'CANCELLED') {
        displayDetails.push(paymentDetails[i]);
      }
    }
    return displayDetails;
  };

  previewVoucher = () => {
    const { request: { voucherDetails } } = this.props;
    window.open(voucherDetails.voucherLink.link, 'blank');
  };

  voucherHandler = () => {
    const {
      onItineraryAction,
      request: { bookings = [] },
      showSnackbar,
    } = this.props;
    let unConfirmedBooking = false;
    for (let i = 0; i < bookings.length; i++) {
      if (bookings[i].status !== 'BOOKING_CONFIRMED'
        && bookings[i].status !== 'BOOKING_CANCELLED'
        && bookings[i].status !== 'DISCARDED'
        && bookings[i].status !== 'VOUCHER_GENERATION_REQUESTED'
        && bookings[i].status !== 'VOUCHER_GENERATION_PENDING'
        && bookings[i].status !== 'VOUCHER_GENERATED') {
        unConfirmedBooking = true;
        // showSnackbar('All booking must be confirmed before generating voucher', 'error');
        break;
      }
    }
    if (unConfirmedBooking) {
      showSnackbar('All booking must be confirmed before generating voucher', 'error');
    } else {
      onItineraryAction('generate_voucher');
    }
  };

  itineraryVisibility = () => {
    const { request: { itineraries = [] } } = this.props;
    let showV1Button = true;
    let showV2Button = true;
    let hasV2Only = true;
    let hasV1Only = true;
    for (let i = 0; i < itineraries.length; i++) {
      if (!itineraries[i].itineraryVersion) {
        hasV2Only = false;
      }
      if (itineraries[i].itineraryVersion === 2) {
        hasV1Only = false;
      }
    }
    if (hasV2Only) {
      showV1Button = false;
    }
    if (hasV1Only) {
      showV2Button = false;
    }
    if (!showV1Button && !showV2Button) {
      showV2Button = true;
    }
    return {
      showV1Button,
      showV2Button,
    };
  };

  renderActiveTab = () => {
    const {
      classes, request, onItineraryAction, onBookingAction, isResendingConfirmation,
      actionHandler, onRemarkUpdate, expert, onOrderUpdateRequest, isGettingBookings,
      bookings: allBookings = {},
    } = this.props;
    const { selectedTab, showQuotation, isOps } = this.state;
    const {
      reachOuts = [], status: reqStatus,
      itineraries = [], crmLead, _id: reqId,
      // orderInfo = {},
    } = request;
    const bookings = allBookings[reqId] || [];
    const { showV1Button, showV2Button } = this.itineraryVisibility();
    switch (selectedTab) {
      case 'CURRENT':
        return (
          <div className={classes.requestDetails}>
            <div className={classes.headerRow}>
              <Remarks
                expert={expert}
                remarks={request.remarks}
                onUpdate={onRemarkUpdate}
              />
              {(expert.roles.includes(ROLES.TECH) || expert.roles.includes(ROLES.TEAM_LEAD) || expert.roles.includes(ROLES.FLIGHT))
                && (bookings?.length || crmLead)
                && reqStatus !== 'TRIP_CANCELLED' ? (
                  <Button
                    variant="plain"
                    className={clsx(classes.cancelReqButton, classes.editButton)}
                    onClick={() => actionHandler('CANCEL_REQUEST')}
                  >
                    <CancelBookingIcon width={16} height={16} />
                    <span className={classes.cancelReqText}>Cancel request</span>
                  </Button>
                ) : null}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Button
                  variant="plain"
                  className={classes.editButton}
                  onClick={() => actionHandler('ADD_REQUIREMENT')}
                >
                  {/* <img src={edit} alt="edit" className={classes.buttonImg} /> */}
                  <VisibilityIcon className={classes.iconClass} />
                  View Req.
                </Button>
              </div>
            </div>
            {request && <RequestSummary request={request} />}
            {(reqStatus !== 'FRESH' && reqStatus !== 'LOST' && reqStatus !== 'BAD_KARMA') ? (
              <div className={classes.createItnRow}>
                <Typography className={classes.itineraryText}>Itineraries</Typography>
                {(isOps && showV1Button) ? (
                  <Button
                    variant="plain"
                    onClick={() => onItineraryAction('create_ops_itinerary', {})}
                    className={classes.createItnBtn}
                  >
                    <Send className={classes.sendIcon} />
                    Create itinerary
                  </Button>
                ) : null}
                {bookings?.length ? (
                  <Button
                    variant="plain"
                    loading={isResendingConfirmation}
                    onClick={() => actionHandler('resend_confirmation')}
                    className={classes.createItnBtn}
                  >
                    <Send className={classes.sendIcon} />
                    Resend confirmation
                  </Button>
                ) : null}
                {showV2Button ? (
                  <Button
                    variant="plain"
                    onClick={() => onItineraryAction('send_new', {})}
                    className={classes.createItnBtn}
                  >
                    <Send className={classes.sendIcon} />
                    Create new itinerary
                  </Button>
                ) : null}
                {showV1Button ? (
                  <Button
                    variant="plain"
                    onClick={() => onItineraryAction('send', {})}
                    className={classes.createItnBtn}
                  >
                    <Send className={classes.sendIcon} />
                    Send itinerary
                  </Button>
                ) : null}
                {(showQuotation && showV1Button) && (
                  <Button
                    variant="plain"
                    onClick={() => onItineraryAction('quote')}
                    className={classes.createItnBtn}
                  >
                    <MonetizationOnOutlinedIcon className={classes.sendIcon} />
                    Request Quotation
                  </Button>
                )}
              </div>
            ) : null}
            {itineraries.map((itinerary, i) => {
              if (itinerary.status === 'MODIFIED') return null;
              const id = `more${i}`;
              const dynamicFld = `anchorEl_${i}`;
              const { [dynamicFld]: anchorEl } = this.state;
              const {
                status: itnStatus,
                sendCount,
                identifier,
                confirmedAt,
              } = itinerary;
              let options = [...this.options];
              if (sendCount === 0) {
                options[1].title = 'Send itinerary';
              } else {
                options[1].title = 'Resend itinerary';
              }
              if (itinerary.itineraryVersion === 2) {
                options.splice(0, 1);
                options.splice(0, 1);
              }
              let showAcceptBtn = true;
              if (itnStatus === 'ITINERARY_ACCEPTED' || itnStatus === 'APPROVED'
                || itnStatus === 'DRAFTED' || itnStatus === 'AWAITING_MODIFICATION_APPROVAL'
                || itnStatus === 'CANCELLED') {
                showAcceptBtn = false;
              }
              if (itnStatus === 'APPROVED') {
                options = [
                  options[1],
                  // {
                  //   Icon: VisibilityIcon,
                  //   title: 'Preview itinerary',
                  // }
                ];
                if (expert.roles.includes(ROLES.TECH)) {
                  options.push({
                    Icon: ModifyBookingIcon,
                    title: 'Modify itn. (T)',
                  });
                }
                if (expert.roles.includes(ROLES.FELLOW) || expert.roles.includes(ROLES.TEAM_LEAD)
                  || expert.roles.includes(ROLES.OPERATION) || expert.roles.includes(ROLES.TECH)
                  || expert.roles.includes(ROLES.EXPERT)) {
                  options.push({
                    Icon: ModifyBookingIcon,
                    title: 'Modify itinerary',
                  });
                }
                options.push({
                  title: 'View final itinerary',
                  Icon: PageViewIcon,
                });
                if ((expert.roles.includes(ROLES.TEAM_LEAD) || expert.roles.includes(ROLES.TECH)
                  || expert.roles.includes(ROLES.ADMIN)) && itineraries.length > 1) {
                  options.push({
                    Icon: CancelIcon,
                    title: 'Cancel itinerary',
                  });
                }
              }
              if (itnStatus === 'AWAITING_MODIFICATION_APPROVAL') {
                options = options.filter((o) => o.title !== 'Edit itinerary');
              }
              if (!expert.roles.includes(ROLES.EXPERT)) {
                options = options.filter((o) => o.title !== 'Request quotation');
              }
              // if (!itinerary.link && !itinerary.document?.link) {
              //   options = options.filter((o) => o.title !== 'Preview itinerary');
              // }
              // if (itinerary.status === 'DRAFTED'
              //   && !options.find((o) => o.title === 'Preview itinerary')) {
              //   options.splice(options.length - 1, 0, {
              //     title: 'Preview itinerary',
              //     Icon: VisibilityIcon,
              //   });
              // }
              if (['APPROVED', 'AWAITING_MODIFICATION_APPROVAL', 'CONFIRMED'].includes(itinerary.status)
                || reqStatus === 'TRIP_CANCELLED') {
                options = options.filter((o) => o.title !== 'Delete itinerary');
              }
              if (itinerary.status === 'DRAFTED' || itinerary.status === 'APPROVED' || itinerary.status === 'ITINERARY_SENT') {
                options.splice(options.length - 1, 0, {
                  title: 'Clone & Edit',
                  Icon: CloneIcon,
                });
              }
              if (itinerary.itineraryVersion === 2 && ['ITINERARY_SENT', 'ITINERARY_ACCEPTED', 'CONFIRMED', 'APPROVED'].includes(itinerary.status) && !itinerary.isPublic) {
                options.splice(options.length - 1, 0, {
                  title: itinerary.isTemplate ? 'Remove as Template' : 'Save as Template',
                  Icon: itinerary.isTemplate ? RemoveCircleOutlineIcon : CheckCircleOutlineIcon,
                });
              }
              if (itinerary.itineraryVersion === 2 && ['ITINERARY_SENT'].includes(itinerary.status)) {
                options.splice(options.length - 1, 0, {
                  title: 'Archive',
                  Icon: ArchiveOutlinedIcon,
                });
              }
              if (itinerary.itineraryVersion === 2 && ['ARCHIVED'].includes(itinerary.status)) {
                options.splice(options.length - 1, 0, {
                  title: 'Unarchive',
                  Icon: UnarchiveOutlinedIcon,
                });
              }
              if (itinerary.itineraryVersion === 2) {
                options.splice(options.length - 1, 0, {
                  title: 'Preview',
                  Icon: VisibilityIcon,
                });
              }
              if (itinerary.itineraryVersion === 2
                && (itinerary.status === 'ITINERARY_SENT' || itinerary.status === 'DRAFTED')) {
                options = options
                  .filter((o) => o.title !== 'Resend itinerary' && o.title !== 'Send itinerary');
              }
              if (reqStatus === 'TRIP_CANCELLED') {
                options = [{
                  title: 'View final itinerary',
                  Icon: PageViewIcon,
                }];
              }
              return (
                <div
                  key={itinerary._id}
                  className={clsx(classes.card, classes.itineraryCard)}
                >
                  <div className={classes.itemRow}>
                    <Typography
                      className={classes.itineraryName}
                    >
                      {itinerary?.document?.fileName
                        ? itinerary.document.fileName : `# ITINERARY ${identifier}`}
                      <span
                        className={classes.sub}
                      >
                        {
                          (itinerary.tags && itinerary.tags.includes('DATES_NOT_FINAL'))
                            ? (
                              <Tooltip
                                title="Dates not final"
                                tabIndex="-1"
                                className={classes.tooltip}
                              >
                                <EventBusyIcon
                                  className={classes.tags}
                                />
                              </Tooltip>
                            ) : null
                        }
                        {
                          (itinerary.tags && itinerary.tags.includes('WALLET_CUSTOMER'))
                            ? (
                              <Tooltip
                                title="Wallet customer"
                                tabIndex="-1"
                                className={classes.tooltip}
                              >
                                <AccountBalanceWalletIcon
                                  className={classes.walletIcon}
                                />
                              </Tooltip>
                            ) : null
                        }
                      </span>
                    </Typography>
                    {(showAcceptBtn
                      && (itinerary.itineraryVersion !== 2 || request.b2bPartner
                        || (request.primaryCustomerData?.email === 'cred.bookings@flynote.in')))
                      ? (
                        <Button
                          className={classes.itineraryActionBtn}
                          variant="plain"
                          onClick={() => this.handleItineraryAction(i, 'mark_accepted')}
                        >
                          <Check className={classes.sendIcon} />
                          MARK ACCEPTED
                        </Button>
                      ) : null}
                    {(itnStatus === 'AWAITING_MODIFICATION_APPROVAL' && expert.roles.includes(ROLES.TEAM_LEAD)) ? (
                      <Button
                        className={classes.itineraryActionBtn}
                        variant="plain"
                        onClick={() => this.handleItineraryAction(i, 'approve_reject_itinerary')}
                      >
                        <Check className={classes.sendIcon} />
                        APPROVE / REJECT CHANGES
                      </Button>
                    ) : null}
                    {(!_.isNil(confirmedAt)
                      && !_.isEmpty(request.tokenPaymentDate)
                      && itnStatus !== 'APPROVED'
                      && expert.roles.includes(ROLES.TEAM_LEAD)) ? (
                        <Button
                          className={classes.itineraryActionBtn}
                          variant="plain"
                          onClick={() => this.handleItineraryAction(i, 'request_booking')}
                        >
                          <CardTravelIcon className={classes.sendIcon} />
                          REQUEST BOOKING
                        </Button>
                      ) : null}
                    {
                      ['ITINERARY_SENT', 'CONFIRMED', 'APPROVED'].includes(itnStatus)
                        ? (
                          <Button
                            variant="plain"
                            aria-describedby={id}
                            className={classes.itineraryActionBtn}
                            onClick={() => this.downloadPdf(request, itinerary)}
                          >
                            <GetAppOutlinedIcon className={classes.optionIcon} />
                            PDF
                          </Button>
                        )
                        : null
                    }
                    <Button
                      variant="plain"
                      aria-describedby={id}
                      className={classes.itineraryActionBtn}
                      onClick={() => this.copyPublicLink(request.uuId, itinerary._id)}
                    >
                      <CloneIcon className={classes.optionIcon} />
                      Copy Link
                    </Button>
                    <Button
                      variant="plain"
                      aria-describedby={id}
                      className={classes.itineraryActionBtn}
                      onClick={(e) => this.handleChange(dynamicFld, e.currentTarget)}
                    >
                      <MoreVert className={classes.optionIcon} />
                      MORE
                    </Button>
                    <Popover
                      id={id}
                      open={Boolean(anchorEl)}
                      PaperProps={{
                        className: classes.popperClass,
                      }}
                      variant="plain"
                      anchorEl={anchorEl}
                      onClose={() => this.handleChange(dynamicFld, null)}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <div className={classes.popoverContainer}>
                        {options.map(({ Icon, title }) => {
                          const showDelete = title === 'Delete itinerary' || title === 'Cancel itinerary';
                          return (
                            <Button
                              key={title}
                              variant="plain"
                              onClick={() => this.handleItineraryAction(i, title, dynamicFld)}
                              className={clsx(classes.optionItem,
                                showDelete && classes.deleteOption)}
                            >
                              <Icon
                                className={clsx(showDelete && classes.deleteOption,
                                  classes.optionIcon)}
                              />
                              {title}
                            </Button>
                          );
                        })}
                      </div>
                    </Popover>
                  </div>
                  {itinerary.pricing?.finalSellPrice > 0
                    ? (
                      <div
                        className={classes.itemRow}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-end',
                        }}
                      >
                        <div>
                          SP - ₹{(itinerary.pricing.finalSellPrice/100).toLocaleString()}
                        </div>
                        <div>
                          Margin - ₹{((itinerary.pricing.finalSellPrice - itinerary.pricing.totalCostPrice)/100).toLocaleString()}
                        </div>
                        {
                          request.promoWalletBalance > 0
                            ? (
                              <div style={{ color: '#CC3254', fontWeight: 'bold' }}>
                                Usable Promo Cash - ₹{(Math.min(PROMO_CASH_LIMIT * 100, request.promoWalletBalance)/100).toLocaleString()}
                              </div>
                            )
                            : null
                        }
                      </div>
                    ) : null}
                  <div className={classes.itnStatusRow}>
                    <Typography
                      className={classes.itineraryStatus}
                    >
                      {itnStatus?.replace(/_/g, ' ')}
                    </Typography>
                    <Typography className={classes.createdAt}>
                      {`Created at - ${moment(itinerary.created_at)
                        .format('DD MMM YY hh:mm A')}`}
                    </Typography>
                  </div>
                </div>
              );
            })}
          </div>
        );
      case 'FORM-FILLS': {
        const totalLen = reachOuts.length;
        return (
          <div className={classes.detailSubSection}>
            {reachOuts.map((reachOut, i) => {
              let {
                source = '',
              } = reachOut;
              if (!source) source = reachOut.contactRequest ? 'Website' : '';
              if (!source) source = reachOut.fbAdLead ? `FB AD Lead ${reachOut.fbAdLead?.adId}` : '';
              if (!source) source = reachOut.intercom ? 'Intercom Lead' : '';
              const reachOutData = getReachOutInfo(reachOut);
              const momentDate = moment(reachOut.created_at);
              const date = momentDate.format('DD MMM');
              const time = momentDate.format('hh:mm A');
              return (
                <div key={reachOut._id} className={classes.dateWrapper}>
                  <div className={classes.dateSection}>
                    <Typography className={classes.creationDate}>{date}</Typography>
                    <DotLine isLast={i === 0} />
                  </div>
                  <div className={classes.card}>
                    <div className={classes.itemRow}>
                      <Typography
                        className={classes.tripNumber}
                      >
                        {`# ${totalLen - i} Trip request`}
                      </Typography>
                      <Typography
                        className={classes.source}
                      >
                        {` - ${source.replace('_', ' ')}`}
                      </Typography>
                      <Typography className={classes.time}>{time}</Typography>
                    </div>
                    <div className={classes.itemRow}>
                      {this.box(classes, 'Destination', reachOutData.places, classes.infoReachOut)}
                      {this.box(classes, 'Travel month', reachOutData.travelMonth, classes.infoReachOut)}
                      {this.box(classes, 'Trip type', reachOutData.occasion, classes.infoReachOut)}
                      {this.box(classes, 'Duration', reachOutData.duration, classes.infoReachOut)}
                    </div>
                    <div className={clsx(classes.itemRow)}>
                      {this.box(classes, 'Origin', reachOutData.origin, classes.infoReachOut)}
                      {this.box(classes, 'Travellers', reachOutData.travellers, classes.infoReachOut)}
                      {this.box(classes, 'Flights booked', reachOutData.flightsBooked, classes.infoReachOut)}
                      {this.box(classes, 'Flexible dates', reachOutData.datesFlexible, classes.infoReachOut)}
                    </div>
                    {
                      reachOutData.message && (
                        <div className={clsx(classes.itemRow, classes.itemRow)}>
                          {this.box(classes, 'Comment', reachOutData.message, classes.infoReachOut)}
                        </div>
                      )
                    }
                  </div>
                </div>
              );
            }).reverse()}
          </div>
        );
      }
      case 'BOOKINGS': {
        const totalLen = bookings.length;
        const { exceptFellow } = this.state;
        if (isGettingBookings) {
          return (
            <div className={classes.detailSubSectionLoader}>
              <LinearProgress className={classes.loader} />
            </div>
          );
        }
        return (
          <div className={classes.detailSubSection}>
            <div className={classes.paymentButtons} style={{ justifyContent: 'flex-start' }}>
              {/* {(expert.roles.includes(ROLES.TEAM_LEAD) && orderInfo.orderData?.some((order) => order.amountPaid > 0)) || true ? ( */}
              <Button
                className={classes.sendBookingMailBtn}
                variant="plain"
                onClick={() => actionHandler('SEND_BOOKING_MAIL')}
              >
                Send Booking Mail To Operation
              </Button>
              {/* ) : null} */}
            </div>
            {bookings.map((booking, i) => {
              const {
                _id, costPrice,
                status: bookingStatus, amountPaid = 0,
                amountPayable = 0, supplier, actualSupplier, actualCostPrice, payments = [],
                currency,
              } = booking;
              const {
                flights, stays, activities, transfers,
                services, currencyCode, flightParts,
                itinerary,
              } = this.getItineraryBookedInfo(i, booking._id);
              let showFlights = false;
              const bookingText = this.getBookedTypeStr(activities, transfers,
                stays, flights, services);
              let packageType = 'Land package';
              if (flights !== 0) {
                packageType = 'Flights';
                showFlights = true;
              } else if (services !== 0) {
                packageType = 'Service';
              }
              // const id = `booking_more${i}`;
              // const dynamicFld = `booking_anchorEl_${i}`;
              // const { [dynamicFld]: anchorEl } = this.state;
              return (
                <div key={_id} className={classes.dateWrapper}>
                  <div className={classes.dateSection}>
                    <Typography
                      className={classes.creationDate}
                    >
                      {moment(booking.created_at)
                        .format('DD MMM')}
                    </Typography>
                    <DotLine isLast={totalLen === i + 1} />
                  </div>
                  <div className={clsx(
                    classes.bookingCardWrapper, {
                      [classes.gravedOut]: itinerary.status === 'MODIFIED',
                    }
                  )}
                  >
                    <div
                      className={classes.bookingCard}
                    >
                      <div className={classes.rightCol}>
                        <div className={classes.bookingActionRow}>
                          {((bookingStatus === 'BOOKING_REQUESTED' || bookingStatus === 'AWAITING_TOKEN')
                            && this.canConfirmAcceptBooking && itinerary.status === 'APPROVED') ? (
                              <Button
                                className={classes.viewDetailsBtn}
                                variant="plain"
                                onClick={() => onBookingAction('ACCEPT_BOOKING', i, _id)}
                              >
                                Accept booking
                              </Button>
                            ) : null}
                          {(bookingStatus === 'BOOKING_ACCEPTED' && this.canConfirmAcceptBooking && itinerary.status === 'APPROVED') ? (
                            <Button
                              className={classes.viewDetailsBtn}
                              variant="plain"
                              onClick={() => onBookingAction('CONFIRM_BOOKING', i, _id)}
                            >
                              Confirm booking
                            </Button>
                          ) : null}
                          {
                            ((itinerary.status === 'APPROVED'
                              && (bookingStatus === 'BOOKING_ACCEPTED' || bookingStatus === 'BOOKING_CONFIRMED'
                                || bookingStatus === 'VOUCHER_GENERATION_PENDING' || bookingStatus === 'BOOKING_CANCELLED'
                                || bookingStatus === 'VOUCHER_GENERATED' || bookingStatus === 'VOUCHER_GENERATION_REQUESTED'))
                            || ((itinerary.status === 'CANCELLED')
                              && (bookingStatus === 'BOOKING_CANCELLED')
                              && booking.allowRefund))
                            && exceptFellow
                            && expert.roles.includes(ROLES.PAYOUT_ADMIN)
                              ? (
                                <>
                                  <Button
                                    className={classes.viewDetailsBtn}
                                    variant="plain"
                                    onClick={() => onBookingAction('booking_transaction', i, _id)}
                                  >
                                    Record Payment
                                  </Button>
                                  <Button
                                    className={classes.viewDetailsBtn}
                                    variant="plain"
                                    onClick={() => onBookingAction('booking_transaction_payment', i, _id)}
                                  >
                                    Make Payment
                                  </Button>
                                </>
                              ) : null
                          }
                          {supplier.payoutsDisabled && itinerary.status === 'APPROVED' && (!actualSupplier || !actualCostPrice) && (expert.roles.includes(ROLES.ADMIN) || expert.roles.includes(ROLES.TECH) || expert.roles.includes(ROLES.TEAM_LEAD) || expert.roles.includes(ROLES.OPERATION)) ? (
                            <Button
                              className={classes.viewDetailsBtn}
                              variant="plain"
                              onClick={() => onBookingAction('add_actual_supplier', i, _id)}
                            >
                              Add actual supplier
                            </Button>
                          ) : null}
                          <Button
                            className={classes.viewDetailsBtn}
                            variant="plain"
                            onClick={() => onBookingAction('VIEW_BOOKING', i, _id)}
                          >
                            View details
                          </Button>
                          {exceptFellow && (bookingStatus === 'DISCARDED' || (bookingStatus !== 'BOOKING_CANCELLED' && itinerary.status === 'APPROVED')) ? (
                            <Button
                              className={classes.viewDetailsBtn}
                              variant="plain"
                              onClick={() => onBookingAction('CANCEL_BOOKING', i, _id)}
                            >
                              Cancel booking
                            </Button>
                          ) : null}
                        </div>
                      </div>
                      <div className={classes.leftCol}>
                        <Typography
                          className={classes.bookingText}
                        >
                          {`${packageType} (${bookingText})`}
                          <span
                            className={classes.sub}
                          >
                            {
                              (itinerary.tags && itinerary.tags.includes('DATES_NOT_FINAL'))
                                ? (
                                  <Tooltip
                                    title="Dates not final"
                                    tabIndex="-1"
                                    className={classes.tooltip}
                                  >
                                    <EventBusyIcon
                                      className={classes.tags}
                                    />
                                  </Tooltip>
                                ) : null
                            }
                            {
                              (itinerary.tags && itinerary.tags.includes('WALLET_CUSTOMER'))
                                ? (
                                  <Tooltip
                                    title="Wallet customer"
                                    tabIndex="-1"
                                    className={classes.tooltip}
                                  >
                                    <AccountBalanceWalletIcon
                                      className={classes.walletIcon}
                                    />
                                  </Tooltip>
                                ) : null
                            }
                          </span>
                        </Typography>
                        {
                          // bookingId && (
                          //   <Typography
                          //     className={classes.bookingId}
                          //   >
                          //     {`Booking ID: ${bookingId}`}
                          //   </Typography>
                          // )
                        }
                        <Typography
                          className={classes.bookingId}
                        >
                          {supplier?.name}
                          {supplier.payoutsDisabled && (expert.roles.includes(ROLES.ADMIN) || expert.roles.includes(ROLES.TECH) || expert.roles.includes(ROLES.TEAM_LEAD) || expert.roles.includes(ROLES.PAYMENT) || expert.roles.includes(ROLES.OPERATION)) && actualSupplier ? (
                            <>
                              <br />
                              <b>{actualSupplier?.name}</b>
                            </>
                          ) : null}
                        </Typography>
                        <Typography
                          className={clsx(classes.itineraryStatus, {
                            [classes.textDark]: itinerary.status === 'MODIFIED',
                          })}
                        >
                          {bookingStatus.replace(/_/g, ' ')}
                        </Typography>
                      </div>
                      <div className={classes.rightCol}>
                        {(expert.roles.includes(ROLES.OPERATION) || expert.roles.includes(ROLES.PAYMENT) || expert.roles.includes(ROLES.PAYOUT_ADMIN)) ? (
                          <div className={classes.row}>
                            <Typography
                              className={classes.bookingCost}
                            >
                              {`CP: ${(costPrice / 100).toFixed(2)} ${currencyCode || currency.code}`}
                            </Typography>
                            <Typography
                              className={classes.bookingCost}
                            >
                              {`Payable: ${(amountPayable / 100).toFixed(2)} ${currencyCode || currency.code}`}
                            </Typography>
                            <Typography
                              className={classes.bookingCost}
                            >
                              {`Paid: ${(amountPaid / 100).toFixed(2)} ${currencyCode || currency.code}`}
                            </Typography>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {showFlights ? flightParts.map(({ _id: fID, flightData }) => {
                      const {
                        departureFlightData, flightType, returnFlightData,
                        baggage, isRefundable,
                      } = flightData;
                      const arrowText = returnFlightData ? ' <=> ' : ' => ';
                      return (
                        <div key={fID} className={classes.flightCard}>
                          <div className={classes.flightRow}>
                            <Typography
                              className={classes.value}
                            >
                              {departureFlightData.from ? departureFlightData.from.name : ''}
                            </Typography>
                            <Typography className={classes.arrow}>{` ${arrowText} `}</Typography>
                            <Typography
                              className={classes.value}
                            >
                              {departureFlightData.to ? departureFlightData.to.name : ''}
                            </Typography>
                          </div>
                          <div className={classes.flightDivider} />
                          <div className={classes.flightRow}>
                            <Typography className={classes.notes}>
                              NOTES:
                            </Typography>
                            <Typography className={classes.value}>
                              {`${departureFlightData.flightNumber} | ${departureFlightData.terminal} |\
 ${flightType} | Baggage: ${baggage} | ${isRefundable ? 'Refundable' : 'Non refundable'}`}
                            </Typography>
                          </div>
                        </div>
                      );
                    }) : null}
                    {
                      (expert.roles.includes(ROLES.OPERATION) || expert.roles.includes(ROLES.PAYMENT) || expert.roles.includes(ROLES.PAYOUT_ADMIN)) && payments.length ? (
                        <div className={classes.flightCard}>
                          <Typography
                            className={classes.bookingText}
                          >
                            Payments
                          </Typography>
                          <table className={classes.w400}>
                            <thead>
                              <tr>
                                <th>
                                  Amount
                                </th>
                                <th>
                                  Status
                                </th>
                                <th>
                                  Type
                                </th>
                                <th>
                                  Date
                                </th>
                                <th>
                                  Reference
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                payments.map((payment) => (
                                  <tr>
                                    <td>
                                      <Typography
                                        className={classes.bookingCost}
                                      >
                                        {Number((payment?.amount || 0) / 100).toFixed(2)}
                                        {'  '}
                                        {currencyCode || currency.code}
                                      </Typography>
                                    </td>
                                    <td>
                                      <Typography
                                        className={classes.bookingCost}
                                      >
                                        {payment?.status}
                                      </Typography>
                                    </td>
                                    <td>
                                      <Typography
                                        className={classes.bookingCost}
                                      >
                                        {payment?.type}
                                      </Typography>
                                    </td>
                                    <td>
                                      <Typography
                                        className={classes.bookingCost}
                                      >
                                        {moment(payment?.date).format('DD MMM YY hh:mm A')}
                                      </Typography>
                                    </td>
                                    <td>
                                      <Typography
                                        className={classes.bookingCost}
                                      >
                                        {payment?.referenceNumber}
                                      </Typography>
                                    </td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </table>
                        </div>
                      )
                        : null
                    }
                  </div>
                </div>
              );
            })}
          </div>
        );
      }
      case 'PAYMENT': {
        const {
          orderInfo: { orderData = [] },
          voucherDetails = {}, b2bPartner,
        } = request;
        if (orderData.length === 0) {
          return null;
        }
        const { refundData = {}, virtualAccountData = {} } = this.props;
        const vData = virtualAccountData[request._id];
        const refunds = (refundData[request._id] || {}).refundMeta || [];
        const {
          installments, amountPaid, unpaidAmount, isRefundLocked, totalTcs = 0,
          orderAmount, orderTransactions = [], refundedAmount, pendingRefundAmount,
          b2bPartnerOrderAmount,
        } = orderData[0];
        let displayDetails = [];
        if (orderTransactions.length) {
          displayDetails = this.displayPaymentDetails(orderTransactions);
        }
        return (
          <div className={classes.detailSubSection}>
            <div className={classes.paymentButtons}>
              {(
                crmLead
                || expert.roles.includes(ROLES.ADMIN)
                || expert.roles.includes(ROLES.PAYMENT))
                && (unpaidAmount > 0) ? (
                  <Button
                    className={classes.acceptPayment}
                    onClick={() => actionHandler('ACCEPT_PAYMENT')}
                    variant="plain"
                  >
                    Accept payment
                  </Button>
                ) : null}
              {(expert.roles.includes(ROLES.PAYOUT_ADMIN)) ? (
                <Button
                  className={classes.refundPayment}
                  onClick={() => actionHandler('NEW_REFUND')}
                  variant="plain"
                >
                  New refund
                </Button>
              ) : null}
              {(expert.roles.includes(ROLES.OPERATION) || expert.roles.includes(ROLES.ADMIN))
                && (reqStatus === 'TRIP_CANCELLED' || pendingRefundAmount) ? (
                  <Button
                    variant="plain"
                    disabled={isRefundLocked}
                    className={classes.refundPayment}
                    onClick={() => actionHandler('REFUND_PAYMENT')}
                  >
                    Record refund
                  </Button>
                ) : null}
              {(vData?.userBankDetails?.ifsc) ? (
                <Button
                  variant="plain"
                  onClick={() => actionHandler('CUSTOMER_BANK_INFO')}
                >
                  Send Payment Link
                </Button>
              ) : null}
              {/* {(expert.roles.includes(ROLES.OPERATION) || expert.roles.includes(ROLES.ADMIN))
                && (reqStatus === 'TRIP_CANCELLED') ? (
                  <Button
                    variant="plain"
                    disabled={isRefundLocked}
                    className={classes.refundPayment}
                    onClick={() => actionHandler('REFUND_FROM_WALLET')}
                  >
                    Refund from wallet
                  </Button>
                ) : null} */}
              {voucherDetails.voucherStatus === 'GENERATED' ? (
                <Button
                  className={classes.modifyOrder}
                  onClick={() => this.previewVoucher()}
                  variant="plain"
                >
                  Preview voucher
                </Button>
              ) : null}
              {reqStatus !== 'PAYMENT_COMPLETE' && reqStatus !== 'TRIP_CANCELLED' ? (
                <Button
                  className={classes.modifyOrder}
                  onClick={onOrderUpdateRequest}
                  variant="plain"
                >
                  Modify installments
                </Button>
              ) : null}
              {request.status === 'PAYMENT_COMPLETE' || request.status === 'PAYMENT_COLLECTION' ? (
                <Button
                  className={classes.modifyOrder}
                  onClick={() => this.voucherHandler()}
                  variant="plain"
                >
                  {voucherDetails.voucherStatus ? 'Edit voucher' : 'Generate voucher'}
                </Button>
              ) : null}
            </div>
            {
              (b2bPartner && b2bPartnerOrderAmount) ? (
                <div className={classes.installmentSummary}>
                  <div className={classes.headers}>
                    <Typography className={classes.paymentTitle}>Total amount paid by customer to cred</Typography>
                    <Typography className={classes.rightAlign}>
                      {`\u20B9 ${Number(b2bPartnerOrderAmount / 100).toFixed(2)}`}
                    </Typography>
                  </div>
                </div>
              ) : null
            }
            <div className={classes.installmentSummary}>
              <div className={classes.headers}>
                <Typography className={classes.paymentTitle}>Total Selling Price</Typography>
                <Typography className={classes.paymentTitle}>Received Amount</Typography>
                <Typography className={classes.paymentTitle}>Remaining Amount</Typography>
              </div>
              <div className={classes.values}>
                <Typography
                  className={classes.paymentValue}
                >
                  {`\u20B9 ${orderAmount / 100}`}
                </Typography>
                <Typography
                  component="span"
                  className={clsx(classes.paymentValue, classes.green)}
                >
                  {`\u20B9 ${amountPaid / 100}`}
                  <Typography className={classes.tcs}>
                    {`(TCS: ${(totalTcs || 0) / 100})`}
                  </Typography>
                </Typography>
                <Typography
                  className={clsx(classes.paymentValue, classes.red)}
                >
                  {`\u20B9 ${unpaidAmount / 100}`}
                </Typography>
              </div>
            </div>
            <div className={classes.installmentContainer}>
              {installments.map((installment, i) => {
                let paidText = installment.paid ? 'PAID' : 'DUE';
                if (!installment.paid && moment(installment.dueDate).unix() < moment().unix()) {
                  paidText = 'OVERDUE';
                }
                const { amountToBePaid, amount } = installment;
                return (
                  <div key={installment.uniqueId || uuid()} className={classes.installmentRow}>
                    <Typography className={classes.installmentText}>
                      {`${i === 0 ? 'Token' : `Installment # ${i}`}`}
                    </Typography>
                    <span
                      className={classes.installmentValue}
                    >
                      {`\u20B9 ${amount / 100}`}
                      {(amountToBePaid !== 0 && amountToBePaid !== amount) ? (
                        <span
                          className={classes.installmentLeftVal}
                        >
                          {`(\u20B9 ${amountToBePaid / 100} due)`}
                        </span>
                      ) : null}
                    </span>
                    <div className={classes.installmentDescContainer}>
                      <Typography
                        className={clsx(classes.installmentTitle, classes[paidText.toLowerCase()])}
                      >
                        {paidText}
                      </Typography>
                      {!installment.paid ? (
                        <Typography
                          className={classes.installmentDesc}
                        >
                          {`${paidText === 'OVERDUE' ? 'since' : 'on'} ${moment(installment.dueDate)
                            .format('DD MMM YY')}`}
                        </Typography>
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </div>
            {(refundedAmount !== 0 || pendingRefundAmount !== 0) ? (
              <div className={classes.refundSummary}>
                <div className={classes.headers}>
                  <Typography className={classes.paymentTitle}>Refunded Amount</Typography>
                  <Typography className={classes.paymentTitle}>Amount to be refunded</Typography>
                </div>
                <div className={classes.values}>
                  <Typography
                    className={classes.paymentValue}
                  >
                    {`\u20B9 ${refundedAmount / 100}`}
                  </Typography>
                  <Typography
                    className={classes.paymentValue}
                  >
                    {`\u20B9 ${pendingRefundAmount / 100}`}
                  </Typography>
                </div>
              </div>
            ) : null}
            {displayDetails.length ? (
              <div className={classes.paymentDetails}>
                <Typography className={classes.paymentSectionTitle}>
                  Transactions
                </Typography>
                {displayDetails.map((detail, i) => {
                  const totalLen = displayDetails.length;
                  const {
                    claimStatus, status, paymentMode, paymentId, surcharge, expertName, documents = [],
                    amount, lastActivity, paymentDate, refundDate, refundId, transactionType = '-',
                  } = detail;
                  let finalID = paymentId || '-';
                  let finalDate = paymentDate;
                  if (status === 'REFUND_SUCCESSFUL') {
                    finalID = refundId;
                    finalDate = refundDate;
                  }
                  return (
                    <div
                      key={`${paymentId || uuid()}_${lastActivity}`}
                      className={classes.dateWrapper}
                    >
                      <div className={classes.dateSection}>
                        <Typography
                          className={classes.creationDate}
                        >
                          {moment(lastActivity).format('DD MMM YY')}
                        </Typography>
                        <DotLine isLast={totalLen === i + 1} />
                      </div>
                      <div className={classes.paymentCard}>
                        <div className={classes.paymentCardRow}>
                          {this.box(classes, 'Payment mode', this.paymentModeMap[paymentMode], classes.infoReachOut)}
                          {this.box(classes, 'Status', status.replace(/_/g, ' '), classes.infoReachOut)}
                          {/* {this.box(classes, 'Amount', amount / 100, classes.infoReachOut)} */}
                          <div className={classes.infoReachOut}>
                            <Typography className={classes.title}>Amount</Typography>
                            <div className={classes.value}>
                              {amount / 100}
                              {surcharge ? (
                                <span
                                  className={classes.surcharge}
                                >
                                  {`( PG Charge: ${surcharge / 100} )`}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className={classes.paymentCardRow}>
                          {paymentMode === 'WALLET'
                            ? this.box(classes, 'Transaction type', transactionType, classes.infoReachOut)
                            : this.box(classes, 'Reference no.', finalID, classes.infoReachOut)}
                          {finalDate ? this.box(classes,
                            'Added on',
                            moment(lastActivity).format('DD MMM YY hh:mm A'),
                            classes.infoReachOut) : null}
                          {this.box(classes,
                            'Paid on',
                            moment(finalDate || lastActivity).format('DD MMM YY hh:mm A'),
                            classes.infoReachOut)}
                          {!finalDate ? this.box(classes, ' ', ' ', classes.infoReachOut) : null}
                        </div>
                        <div className={classes.paymentCardRow}>
                          {this.box(classes, 'Claim Status', claimStatus, classes.infoReachOut)}
                        </div>
                        {expertName ? (
                          <div className={classes.paymentCardRow}>
                            {this.box(classes, 'Expert', expertName, classes.infoReachOut)}
                            {this.box(classes, ' ', '', '')}
                            {this.box(classes, ' ', '', '')}
                          </div>
                        ) : null}
                        {documents.length ? (
                          <IconButton
                            className={classes.paymentDocumentButton}
                            onClick={() => this.paymentPreview(documents[0].link)}
                          >
                            <AttachmentIcon className={classes.paymentIcon} />
                          </IconButton>
                        ) : null}
                      </div>
                    </div>
                  );
                  // }
                })}
              </div>
            ) : null}
            {refunds.length ? (
              <>
                <Typography className={classes.paymentSectionTitle}>
                  Refunds
                </Typography>
                {refunds.map((refund, index) => {
                  const {
                    amount, status, _id,
                    initialWalletSnapshot,
                    created_at: createdAt,
                  } = refund;
                  return (
                    <div
                      key={_id}
                      className={classes.dateWrapper}
                    >
                      <div className={classes.dateSection}>
                        <Typography
                          className={classes.creationDate}
                        >
                          {moment(createdAt).format('DD MMM YY')}
                        </Typography>
                        <DotLine isLast={refunds.length === index + 1} />
                      </div>
                      <div className={classes.paymentCard}>
                        <div className={classes.paymentCardRow}>
                          {this.box(classes, 'Amount', amount / 100, classes.infoReachOut)}
                          {this.box(classes, 'Status', status.replace(/_/g, ' '), classes.infoReachOut)}
                          {this.box(classes, 'Refund Mode', walletsTypes[initialWalletSnapshot?.walletType], classes.infoReachOut)}
                        </div>
                        <div className={classes.paymentCardRow}>
                          {this.box(classes, 'Refunded at', moment(createdAt).format('DD MMM YY hh:mm A'), classes.infoReachOut)}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : null}
          </div>
        );
      }
      default:
        return null;
    }
  };

  paymentPreview = (link) => {
    window.open(link, '_blank');
  };

  render() {
    const {
      expert,
      request,
      // filtration,
      // activeTab,
      requestStatus = {},
      actionHandler,
      classes,
      mode,
      renderLength,
      showSnackbar,
      onItineraryAction,
      showPromoCashDialog,
    } = this.props;
    const { selectedTab } = this.state;
    const {
      primaryCustomer,
      primaryCustomerData = {},
      status = '',
      usId,
      crmLead,
      itineraries = [],
    } = request;
    const finalKey = primaryCustomer || primaryCustomerData;
    const {
      // firstName = '',
      // lastName = '',
      phone = '',
      phoneDialCode = '',
      whatsAppDialCode = '',
      whatsApp = '',
      // email = '',
    } = finalKey;
    // let displayName = firstName;
    // if (lastName) {
    //   displayName = `${firstName} ${lastName}`;
    // }
    const stageText = status;
    let allowedActions = [];
    const actions = requestStatus[(stageText || '').toUpperCase()];
    if (actions) {
      allowedActions = actions.actions || [];
      // filtering hard coded actions
      allowedActions = allowedActions.filter((a) => !this.ignoreActions[a.name]);
      // filtering actions based on my roles and action allowed roles
      allowedActions = allowedActions.filter((a) => a.roles.some((r) => this.myRoles[r]));
      if (!crmLead && !expert.roles.includes(ROLES.ADMIN)) {
        allowedActions = allowedActions.filter((a) => a.name !== 'ACCEPT_PAYMENT');
      }
    }
    // const finalWhatsAppNum = `${whatsAppDialCode} ${whatsApp}`;
    const whatsAppClick = () => {
      if (whatsApp) {
        window.open(`https://wa.me/${whatsAppDialCode.replace('+', '')}${whatsApp}`);
      } else if (!primaryCustomer) {
        actionHandler('add_whatsapp');
      } else {
        showSnackbar('Whatsapp not found & cannot be edited for verified customer', 'info');
      }
    };
    // || (activeTab === 'live' && request.attention > 0)
    // || (activeTab === 'attention' && request.attention < 0)
    if ((renderLength === 0 || !request.requirement) && mode !== 'focused') {
      return <div className={classes.container} />;
    }
    const displayDialCode = phoneDialCode || whatsAppDialCode;
    const displayPhone = phone || whatsApp;
    return (
      <div className={classes.wrapper}>
        <div id="container" className={classes.container}>
          <div className={classes.info}>
            <div className={classes.row}>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                flex: 1,
                alignItems: 'center',
              }}
              >
                {this.box(classes, 'USID', usId, classes.infoBox)}
                {this.box(classes, 'Phone', `${displayDialCode} ${displayPhone}`, classes.infoBox)}
              </div>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                flex: 3,
                alignItems: 'center',
              }}
              >
                <IconButton
                  className={classes.whatsappBtn}
                  onClick={whatsAppClick}
                >
                  <img
                    src={whatsAppImg}
                    className={clsx(classes.whatsApp, !whatsApp && classes.invalidWhatsApp)}
                    alt="w"
                  />
                </IconButton>
                {(request.sio) ? (
                  <Tooltip
                    title="Escape Lead"
                    tabIndex="-1"
                    className={classes.tooltip}
                  >
                    <div className={classes.escape}>E</div>
                  </Tooltip>
                ) : null}
                {
                  (request.tags && request.tags.includes('DATES_NOT_FINAL'))
                    ? (
                      <Tooltip
                        title="Dates not final"
                        tabIndex="-1"
                        className={classes.tooltip}
                      >
                        <EventBusyIcon
                          className={classes.tags}
                        />
                      </Tooltip>
                    ) : null
                }
                <Button
                  variant="plain"
                  onClick={() => showPromoCashDialog()}
                  className={classes.utilityBtn}
                >
                  <AccountBalanceWalletIcon className={classes.walletIcon} />
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    justifyContent: 'center',
                  }}
                  >
                    <div>{`${request.promoWalletBalance === 0 ? 'Add ' : ''}Promo Cash`}</div>
                    {
                      request.promoWalletBalance > 0 && (
                        <div style={{
                          color: '#E67E22',
                          fontSize: 14,
                          fontWeight: 'bold',
                          fontFamily: 'Lato',
                        }}
                        >
                          ₹{request.promoWalletBalance/100}
                        </div>
                      )
                    }
                  </div>
                </Button>
                {(request.reminders?.length) ? (
                  <Tooltip
                    title={`Follow up is set at ${moment(
                      request.reminders[0].scheduledAt
                    ).format('DD MMM YY hh:mm A')}`}
                    tabIndex="-1"
                    className={classes.tooltip}
                  >
                    <AlarmOnIcon className={classes.alarmIcon} />
                  </Tooltip>
                ) : null}
                <Button
                  variant="plain"
                  onClick={() => actionHandler('FOLLOW_UP')}
                  className={classes.utilityBtn}
                >
                  <AlarmOnIcon className={classes.sendIcon} />
                  Follow up
                </Button>
                {/* <Button
                  variant="plain"
                  onClick={() => actionHandler('MARK_UNREACHABLE')}
                  className={classes.createItnBtn}
                >
                  <UnreachableIcon className={classes.sendIcon} />
                  Unreachable
                </Button> */}
                <Button
                  variant="plain"
                  onClick={() => actionHandler('view_details')}
                  className={classes.utilityBtn}
                >
                  {/* <img src={edit} alt="edit" className={classes.buttonImg} /> */}
                  <VisibilityIcon className={classes.iconClass} />
                  View details
                </Button>
              </div>
            </div>
            {/* <div className={classes.row}> */}
            {/* {this.box(classes, 'Phone no.', `${phoneDialCode} ${phone}`)} */}
            {/* {this.box(classes, 'Email ID', email)} */}
            {/* </div> */}
          </div>
          <div className={classes.tabSection}>
            {this.tabs.map((tab) => {
              const selected = selectedTab === tab;
              return (
                <ButtonBase
                  key={tab}
                  className={clsx(classes.tabButton, selected && classes.selectedTab)}
                  onClick={() => this.handleTabSelection(tab)}
                >
                  {tab}
                </ButtonBase>
              );
            })}
            {request.bookings?.length ? (
              <ButtonBase
                className={clsx(classes.tabButton,
                  selectedTab === 'BOOKINGS' && classes.selectedTab)}
                onClick={() => this.handleTabSelection('BOOKINGS')}
              >
                BOOKINGS
              </ButtonBase>
            ) : null}
            {request.orderInfo?.orderData?.[0] ? (
              <ButtonBase
                className={clsx(classes.tabButton,
                  selectedTab === 'PAYMENT' && classes.selectedTab)}
                onClick={() => this.handleTabSelection('PAYMENT')}
              >
                PAYMENT
              </ButtonBase>
            ) : null}
          </div>
          {this.renderActiveTab()}
          <div className={classes.footer}>
            {allowedActions.length || expert.roles.includes(ROLES.FLIGHT) || expert.roles.includes(ROLES.OPERATION) || request.status === 'TRIP_CANCELLED' ? (
              <>
                {['TAKEN_REQUIREMENT', 'ITINERARY_SENT'].includes(request.status)
                  ? (
                    <Button
                      variant="outlined"
                      className={classes.createItnBtn}
                      onClick={() => onItineraryAction('send_new', {})}
                    >
                      <Send className={classes.sendIcon} />
                      Create Itinerary
                    </Button>
                  ) : null}
                {
                  (_.isNil(request.tokenPaymentDate)
                    && !['AWAITING_TOKEN', 'BAD_KARMA'].includes(request.status)
                    && expert.roles.includes('TEAM_LEAD')
                  )
                    ? (
                      <Button
                        variant="outlined"
                        className={classes.createItnBtn}
                        onClick={() => actionHandler('OPEN_TRAV_CLAN_QUOTE_MODAL')}
                      >
                        <MonetizationOnOutlinedIcon className={classes.getLiveQuoteIcon} />
                        Get Live Quote
                      </Button>
                    )
                    : null
                }
                {allowedActions.map(({ name }) => {
                  const lowerName = name.toLowerCase();
                  const displayName = lowerName.replace(/_/g, ' ');
                  return (
                    <Button
                      key={name}
                      variant="outlined"
                      className={classes.createItnBtn}
                      onClick={() => actionHandler(name)}
                    >
                      <img
                        src={STATUS_IMGS[lowerName]}
                        alt={lowerName[0]}
                        className={classes.iconClass}
                      />
                      {`${displayName[0].toUpperCase()}${displayName.slice(1)}`}
                    </Button>
                  );
                })}
                {
                  (
                    itineraries.some((itinerary) => itinerary.status === 'APPROVED' || itinerary.status === 'CONFIRMED')
                    || request.status === 'TRIP_CANCELLED'
                  )
                  && (
                    <>
                      <Button
                        variant="outlined"
                        className={classes.button}
                        onClick={() => actionHandler('verify_documents')}
                      >
                        Verify Customer documents
                      </Button>
                      {/* <Button
                        variant="outlined"
                        className={classes.button}
                        onClick={() => actionHandler('voucher_dialog')}
                      >
                        Upload Customer documents
                      </Button>
                      <Button
                        variant="outlined"
                        className={classes.button}
                        onClick={() => actionHandler('cust_pan_dialog')}
                      >
                        Upload Pan document
                      </Button> */}
                    </>
                  )
                }
              </>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

const styles = (theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 6,
    // position: 'relative',
    backgroundColor: theme.colors.white,
    borderLeft: `1px solid ${theme.colors.primary}`,
    marginLeft: -2,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 6,
    backgroundColor: theme.colors.white,
    boxShadow: '-4px 2px 4px 0 rgba(0,0,0,0.1)',
    overflowY: 'auto',
    opacity: 1,
    // transform: 'translateX(0)',
    transition: 'opacity 0.4s, transform 0.4s ease-in',
  },
  gravedOut: {
    backgroundColor: '#e3e3e3',
  },
  enterLeft: {
    opacity: 0.1,
    transform: 'translateX(100%)',
  },
  walletIcon: {
    height: 16,
    width: 16,
    color: '#369f9b',
    marginRight: 10,
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 40px 0 40px',
    borderBottom: `1px solid ${theme.colors.underline_1}`,
    borderTop: `1px solid ${theme.colors.underline}`,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
    position: 'relative',
  },
  infoReachOut: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  boxSpacing: {
    width: 50,
    height: 1,
  },
  infoBox: {
    // flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  title: {
    color: theme.colors.textDark,
    fontSize: 14,
    fontWeight: 300,
  },
  value: {
    color: theme.colors.black,
    fontSize: 14,
  },
  surcharge: {
    fontSize: 11,
    fontWeight: 'bold',
    color: theme.colors.red,
    marginLeft: 6,
  },
  whatsApp: {
    width: 24,
  },
  invalidWhatsApp: {
    filter: 'grayscale(1)',
  },
  spacing: {
    flex: 1,
  },
  verifiedButton: {
    padding: 4,
    margin: '12px 0 0 12px',
  },
  verifiedUser: {
    width: 20,
    height: 20,
    color: theme.colors.primary,
  },
  alarmIcon: {
    width: 26,
    height: 26,
    margin: '10px 10px 0',
    color: theme.colors.primary,
  },
  tooltip: {
    outline: 'none',
  },
  editLeadButton: {
    // marginLeft: 'auto',
    color: theme.colors.primary,
    fontFamily: 'Lato',
    letterSpacing: 0.5,
    fontWeight: 900,
    fontSize: 12,
    // position: 'absolute',
    right: 0,
    height: 36,
    padding: 6,
  },
  tabSection: {
    minHeight: 40,
    position: 'sticky',
    top: 0,
    zIndex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: theme.colors.primarySelected,
  },
  tabButton: {
    width: 140,
    fontSize: 14,
    color: theme.colors.textDark,
    letterSpacing: 0.5,
    minHeight: 40,
    textAlign: 'center',
    borderBottom: `4px solid ${theme.colors.primarySelected}`,
  },
  selectedTab: {
    fontWeight: 'bold',
    borderBottom: `4px solid ${theme.colors.primary}`,
  },
  requestDetails: {
    display: 'flex',
    flex: 1,
    flexShrink: 0,
    flexDirection: 'column',
    padding: '20px 40px',
    marginBottom: 16,
    // overflow: 'auto',
  },
  headerRow: {
    minHeight: 100,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  bookingActionRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  cancelReqButton: {
    width: '160px !important',
    marginLeft: 'auto',
    marginRight: 10,
  },
  cancelReqText: {
    margin: 0,
    padding: '0 0 0 6px',
    fontSize: 12,
    color: theme.colors.primary,
    letterSpacing: 0.5,
    fontWeight: 'bold',
  },
  editButton: {
    width: 120,
    textTransform: 'uppercase',
    color: theme.colors.primary,
    letterSpacing: 0.5,
    fontWeight: 'bold',
    fontSize: 12,
    height: 36,
    padding: 6,
  },
  state: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.colors.orange,
    textTransform: 'uppercase',
    letterSpacing: 1,
  },
  footer: {
    position: 'sticky',
    bottom: 0,
    width: '100%',
    boxSizing: 'border-box',
    right: 0,
    backgroundColor: theme.colors.white,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    padding: '10px 40px',
    boxShadow: '-3px -2px 20px 8px rgba(0,0,0,0.1)',
  },
  button: {
    fontWeight: 'normal',
    textTransform: 'none',
    borderRadius: 18,
    fontSize: 14,
    marginRight: '0.5rem',
    color: theme.colors.black,
    height: 36,
    minHeight: 0,
  },
  iconClass: {
    width: 16,
    height: 16,
    marginRight: 5,
  },
  getLiveQuoteIcon: {
    width: 16,
    height: 16,
    marginRight: 5,
    color: theme.colors.primary,
  },
  line: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  whatsappBtn: {
    padding: 4,
    marginLeft: 6,
    marginTop: 12,
  },
  createItnRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: 16,
    minHeight: 40,
  },
  utilityBtn: {
    minWidth: 140,
    fontSize: 14,
    fontWeight: 'bold',
    color: theme.colors.primary,
    marginRight: 10,
  },
  createItnBtn: {
    minWidth: 140,
    fontSize: 14,
    fontWeight: 'bold',
    color: theme.colors.primary,
    marginRight: 10,
    marginBottom: 10,
  },
  createItnText: {
    fontSize: 14,
    color: theme.colors.textDark,
  },
  itineraryText: {
    flex: 1,
    color: theme.colors.textDark,
    fontSize: 16,
    fontWeight: 'bold',
    letterSpacing: 0.5,
    // marginBottom: 10,
  },
  itnStatusRow: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  itineraryName: {
    flex: 1,
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.colors.textLight,
  },
  itineraryStatus: {
    flex: 1,
    fontSize: 14,
    fontWeight: 'bold',
    color: theme.colors.orange,
  },
  popoverContainer: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 200,
  },
  textDark: {
    color: `${theme.colors.textDark} !important`,
  },
  popperClass: {
    borderRadius: 4,
    marginTop: 6,
    marginLeft: -40,
  },
  optionItem: {
    fontWeight: 'normal',
    height: 44,
    width: '100%',
    padding: '10px 20px',
    color: theme.colors.black,
    fontSize: 16,
    display: 'flex',
    justifyContent: 'flex-start',
    '&:hover': {
      backgroundColor: theme.colors.underline,
    },
  },
  deleteOption: {
    color: `${theme.colors.red} !important`,
  },
  optionIcon: {
    width: 16,
    height: 16,
    marginRight: 6,
    color: theme.colors.primary,
  },
  card: {
    width: '100%',
    marginLeft: 20,
    borderRadius: 6,
    border: `1px solid ${theme.colors.border}`,
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 20px',
    marginBottom: 20,
  },
  bookingCardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
    borderRadius: 6,
    marginLeft: 20,
    marginBottom: 20,
  },
  bookingCard: {
    // width: '100%',
    // marginLeft: 20,
    // borderRadius: 6,
    border: `1px solid ${theme.colors.border}`,
    display: 'flex',
    flexDirection: 'column',
    padding: '6px 20px',
  },
  arrow: {
    padding: '0 10px',
    fontSize: 16,
  },
  notes: {
    color: theme.colors.textLight,
    fontSize: 14,
    paddingRight: 8,
  },
  flightCard: {
    border: `1px solid ${theme.colors.border}`,
    borderTopColor: 'transparent',
    padding: '0px 20px',
  },
  flightRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '15px 0',
  },
  flightDivider: {
    height: 1,
    width: '100%',
    backgroundColor: theme.colors.border,
  },
  paymentButtons: {
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  acceptPayment: {
    marginLeft: 'auto',
    width: 160,
    color: theme.colors.primary,
  },
  refundPayment: {
    width: 160,
    color: theme.colors.primary,
  },
  modifyOrder: {
    marginLeft: '10px',
    width: 170,
    color: theme.colors.primary,
  },
  installmentSummary: {
    // marginBottom: 16,
    borderRadius: '4px 4px 0 0',
    padding: '15px 0',
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.colors.border}`,
    borderBottom: 'none',
  },
  refundSummary: {
    borderRadius: 4,
    padding: '15px 0',
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.colors.border}`,
    margin: '10px 0',
  },
  headers: {
    padding: '0 15px',
    borderBottom: `1px solid ${theme.colors.border}`,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  values: {
    padding: '0 15px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 40,
  },
  paymentStatus: {
    color: theme.colors.green,
  },
  paymentDetails: {
    marginTop: 20,
    paddingTop: 10,
  },
  paymentSectionTitle: {
    textAlign: 'left',
    fontWeight: 'bold',
    fontSize: 20,
    color: theme.colors.textLight,
    paddingBottom: 20,
    width: '100%',
    boxSizing: 'border-box',
    paddingLeft: 106,
  },
  paymentCard: {
    width: '100%',
    marginLeft: 20,
    borderRadius: 6,
    border: `1px solid ${theme.colors.border}`,
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 20px',
    marginBottom: 20,
    position: 'relative',
  },
  paymentCardRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  },
  paymentCardContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
    padding: '0 15px',
  },
  paymentDocumentButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    width: 42,
    height: 42,
    padding: 4,
    borderRadius: 21,
    boxShadow: '0px 6px 10px 0px rgba(0,0,0,0.2)',
    backgroundColor: theme.colors.white,
  },
  paymentIcon: {
    width: 20,
    height: 20,
    color: theme.colors.primary,
  },
  paymentTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    color: theme.colors.textDark,
    flex: 1,
    paddingBottom: 10,
  },
  paymentValue: {
    paddingTop: 10,
    color: theme.colors.black,
    fontSize: 18,
    fontWeight: 'bold',
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  tcs: {
    color: theme.colors.green,
    fontSize: 13,
    paddingLeft: 4,
    fontWeight: 'bold',
  },
  red: {
    color: theme.colors.red,
  },
  green: {
    color: theme.colors.green,
  },
  installmentContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 0',
    borderRadius: '0 0 4px 4px',
    border: `1px solid ${theme.colors.border}`,
  },
  installmentRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
    paddingLeft: 20,
  },
  installmentText: {
    flex: 1,
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.colors.textLight,
    textAlign: 'left',
    marginRight: 10,
  },
  installmentDescContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  installmentTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.colors.textDark,
    textAlign: 'left',
    paddingRight: 6,
  },
  installmentDesc: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.colors.textLight,
    textAlign: 'left',
  },
  paid: {
    color: theme.colors.green,
  },
  overdue: {
    color: theme.colors.red,
  },
  due: {
    color: theme.colors.textDark,
  },
  sub: {
    verticalAlign: 'sub',
  },
  installmentValue: {
    fontSize: 16,
    color: theme.colors.textLight,
    textAlign: 'left',
    flex: 1,
    fontWeight: 'bold',
  },
  installmentLeftVal: {
    fontSize: 12,
    color: theme.colors.red,
    paddingLeft: 6,
  },
  itineraryCard: {
    width: 'auto',
    marginLeft: 0,
    minHeight: 60,
    // transition: 'box-shadow 0.4s ease-in',
    // '&:hover': {
    //   boxShadow: theme.extraShadows[2],
    // },
  },
  itineraryActionBtn: {
    color: theme.colors.primary,
    fontSize: 12,
    letterSpacing: 0.5,
  },
  sendIcon: {
    width: 16,
    height: 16,
    marginRight: 6,
  },
  createdAt: {
    // flex: 1,
    color: theme.colors.textDark,
    fontSize: 14,
  },
  tags: {
    color: '#369f9b',
    marginLeft: 15,
  },
  pt10: {
    paddingTop: 10,
  },
  escape: {
    background: '-webkit-linear-gradient( yellow, blue)',
    backgroundClip: 'border-box',
    '-webkitBackgroundClip': 'text',
    '-webkitTextFillColor': 'transparent',
    '-webkitTextStroke': '1px red',
    fontSize: 24,
    fontWeight: 900,
    width: 26,
    textAlign: 'center',
    marginLeft: 15,
    borderRadius: '50%',
    border: '1px green solid',
  },
  detailSubSection: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: '20px 40px 0',
    // overflowY: 'auto',
    // marginBottom: 36,
  },
  detailSubSectionLoader: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: '20px 40px 0',
    position: 'relative',
    // overflowY: 'auto',
    // marginBottom: 36,
  },
  loader: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },
  dateWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  dateSection: {
    display: 'flex',
    flexDirection: 'row',
  },
  creationDate: {
    fontSize: 14,
    width: 64,
    fontWeight: 'bold',
    paddingRight: 10,
    color: theme.colors.textDark,
  },
  itemRow: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  noMargin: {
    marginBottom: 0,
  },
  leftCol: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 0',
  },
  rightCol: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  bookingText: {
    fontSize: 16,
    color: theme.colors.black,
    marginBottom: 16,
  },
  bookingId: {
    fontSize: 14,
    color: theme.colors.textDark,
  },
  rightAlign: {
    textAlign: 'right',
  },
  viewDetailsBtn: {
    fontSize: 12,
    letterSpacing: 0.5,
    padding: '4px 8px',
    maxWidth: 145,
  },
  sendBookingMailBtn: {
    fontSize: 12,
    letterSpacing: 0.5,
    padding: '4px 8px',
    maxWidth: 145,
    paddingLeft: '0px',
    textAlign: 'left',
  },
  bookingCost: {
    textAlign: 'right',
    fontSize: 16,
    color: theme.colors.black,
    paddingLeft: 12,
  },
  tripNumber: {
    color: theme.colors.textDark,
    fontWeight: 'bold',
    fontSize: 16,
  },
  source: {
    paddingLeft: 6,
    color: theme.colors.textLight,
    fontSize: 14,
  },
  time: {
    marginLeft: 'auto',
    color: theme.colors.textLight,
    fontSize: 12,
    fontWeight: 'bold',
    letterSpacing: 0.5,
  },
  w400: {
    minWidth: '400px',
  },
});

RequestDetails.propTypes = {
  classes: PropTypes.object,
  expert: PropTypes.object.isRequired,
  request: PropTypes.object.isRequired,
  activeTab: PropTypes.string.isRequired,
  filtration: PropTypes.bool.isRequired,
  requestStatus: PropTypes.object,
  paymentMode: PropTypes.array,
  actionHandler: PropTypes.func.isRequired,
  onItineraryAction: PropTypes.func.isRequired,
  sendItinerary: PropTypes.func.isRequired,
  onRemarkUpdate: PropTypes.func.isRequired,
  onBookingAction: PropTypes.func.isRequired,
  onOrderUpdateRequest: PropTypes.func.isRequired,
  isResendingConfirmation: PropTypes.bool,
  showSnackbar: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  renderLength: PropTypes.number.isRequired,
  getBookings: PropTypes.func.isRequired,
  bookings: PropTypes.object.isRequired,
  isGettingBookings: PropTypes.bool.isRequired,
  getRefundData: PropTypes.func.isRequired,
  refundData: PropTypes.object.isRequired,
  getVirtualAccountData: PropTypes.func.isRequired,
  virtualAccountData: PropTypes.object.isRequired,
  token: PropTypes.string,
  showPromoCashDialog: PropTypes.func.isRequired,
};

export default withStyles(styles)(RequestDetails);
